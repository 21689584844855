<template>
<div id="login">
    <div id="content">
      <div id="bgimg"></div>
      <div id="bgform">
        <div class="title"></div>
         <div style="padding-left: 20%">
             <el-radio  v-model="radio" label="1">账号密码登录</el-radio>
         </div>
          <form class="form1" autocomplete="off" @submit.prevent="onSubmit">
              <div style="overflow: hidden;height: 100%">
                  <el-input
                          placeholder="请输入您的账号"
                          v-model="UserName">
                      <i slot="prefix" class="el-icon-login-user"></i>
                  </el-input>
                  <el-input
                          placeholder="请输入您的密码"
                          v-model="PassWord"
                          show-password
                          class="passwordInput"
                  >
                      <i slot="prefix" class="el-icon-login-password"></i>
                  </el-input>
                  <div style="padding-left: 5%;margin-top: -1%">
                      <el-checkbox v-model="checked">记住密码</el-checkbox>
                  </div>
                  <button class="btn" slot="reference" @click="btn">登录</button>
              </div>
              <!--<button v-loading.fullscreen.lock="fullscreenLoading" class="btn" @click="btn()" >登录</button>-->
          </form>
      </div>
  </div>
    <div id="ma"></div>
    <div class="logoInfo"><a href="#" @click="guanwang" style="color: #252b39;text-decoration: none">@2021 &copy;新疆兵团勘测设计院（集团）有限责任公司</a>
        <br>集团公司市场部 | 测绘分院 研发中心</div>
</div>
</template>

<script>
    const Base64 = require('js-base64').Base64
    export default {
        name: "login",
         data(){
            return{
                radio:'1',
                UserName: "",
                PassWord: "",
                checked:false,
            }
         },
        methods:{
            onSubmit(){return false;},
            guanwang(){
              window.open('https://www.btks.cn/')
            },
            async btn(){
                   const {data:res}= await this.$http.post('https://customer.xjbtgis.cn/api/v1.0/login/loginEvaluate?loginTel='+this.UserName+"&password="+this.PassWord)
                //    const {data:res}= await this.$http.post('http://localhost:8815/api/v1.0/login/loginEvaluate?loginTel='+this.UserName+"&password="+this.PassWord)
                if(res.status!=="2000"){
                    this.$message.error('登录失败！'+res.message);
                }else {
                    const {data:res2}= await this.$http.get('/v1.0/owner/find/ownerByPhone/'+res.data.user.loginTel)
                    if(res2.status=='2004'){
                        console.log(res2.data)
                        window.sessionStorage.setItem("ownerRole",res2.data.ownerRole);
                        window.sessionStorage.setItem("customerTel",res2.data.phone);
                    }else {
                        window.sessionStorage.setItem("ownerRole",null);
                        window.sessionStorage.setItem("customerTel",null);
                    }
                    window.sessionStorage.setItem("token",res.data.token);
                    window.sessionStorage.setItem("userid",res.data.user.id);
                    window.sessionStorage.setItem("roleId",res.data.user.roleId);
                    window.sessionStorage.setItem("name",res.data.user.userName);
                    this.$router.push({ path: '/homepage'});
                    this.setUserInfo()
                }
            },
            setUserInfo(){
                if(this.checked){
                    this.setCookie("username",this.UserName)
                    // base64加密密码
                    let passWord = Base64.encode(this.PassWord)
                    this.setCookie("password",passWord)
                    this.setCookie("remember",this.checked)
                }else{
                    this.setCookie("username","")
                    this.setCookie("password","")
                    this.setCookie("remember",this.checked)
                }
            },
            getCookie: function (key) {
                if (document.cookie.length > 0) {
                    var start = document.cookie.indexOf(key + '=')
                    if (start !== -1) {
                        start = start + key.length + 1
                        var end = document.cookie.indexOf(';', start)
                        if (end === -1) end = document.cookie.length
                        return unescape(document.cookie.substring(start, end))
                    }
                }
                return ''
            },
            setCookie: function (cName, value, expiredays) {
                var exdate = new Date()
                exdate.setDate(exdate.getDate() + expiredays)
                document.cookie = cName + '=' + decodeURIComponent(value) +
                    ((expiredays == null) ? '' : ';expires=' + exdate.toGMTString())
            },
        },
        created(){
            let userNameCookie=this.getCookie("username");
            let passwordCookie = Base64.decode(this.getCookie("password"));
            if(this.getCookie("remember")=='true'){
                this.UserName=userNameCookie;
                this.PassWord=passwordCookie;
                this.checked=true;
            }else {
                this.checked=false;
            }
        },
    }
</script>

<style scoped >
    #login{
       margin: 0;
       padding: 0;
       width: 100%;
       height: 100vh;
       background-color: #DCE6F2;
     /* flex让子div居中  */
       display: flex;
       justify-content: center;
       align-items: center;
    }
    #content{
       width: 66rem;
       height: 38rem;
       background-color: #ffffff;
       border-radius: 1.6rem;
        margin-left: 3.5%;
    }
    #bgimg{
        float: left;
        background: url("../assets/img/loginbg.png");
        background-position: center;
        background-repeat: no-repeat;
        width: 55%;
        height: 100%;
    }
    #bgform{
        padding-top: 1%;
        float: left;
        width: 45%;
        height: 100%;
    }
    .title{
        margin-top: 20%;
        height: 15%;
        background: url("../assets/img/logintitle2.png") no-repeat;
        background-size: 70% 80%;
    }
    /deep/ .el-radio__label{
        color: #A7A7A7 !important;
        font-size: 1.1rem;
    }
    .form1 {
        padding: 3.15rem 2.75rem;
        text-align: left;
        background: #fff;
        border-radius: 15px;
        margin-left: -4%;
        margin-top: -2%;
        width: 62%
    }
    .btn {
        background-image: linear-gradient(90deg, #51A1FA 0%, #4b88e3 74%);
        border-radius: 1.3rem;
        width: 99%;
        border: 1px solid var(--blue);
        cursor: pointer;
        font-size: 0.8rem;
        font-weight: bold;
        letter-spacing: 0.1rem;
        padding: 0.1rem 3rem;
        text-transform: uppercase;
        color: white;
        transition: transform 80ms ease-in;
        margin-top: 4.5%;
        transition: 0.8s !important;
        height: 2.4rem;
        margin-left: 1%;
    }
    .btn:hover {
        border-radius: 4rem;
    }
    .passwordIcon{
        position: absolute;
        margin-top: -25px;
        width: 22px;
        height: 22px;
        background: url("../assets/img/pswd.png");
    }
    /deep/ .el-input__inner{
        background-color: #ffffff;
        height:3rem;
        border-radius: 1.5rem;
        padding-left:55px;
    }
    /deep/ .el-input--prefix .el-input__inner {
        padding-left: 3rem;
    }
    /deep/ .el-input__inner::placeholder {
        color: #a4a4ae;
        font-size: 0.8vw;
    }
    .passwordInput{
        margin-top: 5%;
    }
    .yanzhengInput{
        margin-top: 5%;
        width: 60%;
    }
    /deep/ .el-checkbox{
        margin-top: 5%;
    }
    .el-icon-login-user{
        background: url(../assets/img/user.png) center no-repeat;
        background-size: contain;
        margin-top: 99%;
        margin-left: 80%;
    }
    .el-icon-login-user:before{
        content: "替";
        font-size: 16px;
        visibility: hidden;
    }
    .el-icon-login-password{
        background: url(../assets/img/pswd.png) center no-repeat;
        background-size: contain;
        margin-top: 99%;
        margin-left: 80%;
    }
    .el-icon-login-password:before{
        content: "替";
        font-size: 16px;
        visibility: hidden;
    }
    #ma{
        width: 10rem;
        height: 10rem;
        background: url("../assets/img/erweima.jpg") no-repeat;
        background-size: 100% 100%;
        margin-top: 23.2%;
        border-radius: 0.7rem;
        margin-left: 1%;
    }
    .logoInfo{
        position: absolute;
        text-align: center;
        bottom:4%;
        width: 100%;
        font-size: 0.9rem;
        color:#252b39;
    }
</style>
