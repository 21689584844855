<template>

  <el-container class="contain">
    <el-header>
      <daohangnav></daohangnav>
    </el-header>
    <el-container>
      <el-main>
        <router-view name='main'></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
  import daohang from './daohanglan.vue';
  export default {
    data () {
      return {
      }
    },
    methods: {
    },
    components:{
      daohangnav: daohang,
    },

  }
</script>

<style lang="less" scoped>
  .contain {
    height: 100vh;
  }

  .el-header {
    height: 7% !important;
    width: 100%;
    background-color: var(--heiye);
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    align-items: center;
    color: #fff;
    font-size: 26px;
  }

  .el-main {
    background-color: #eff6fb ;
    padding: 0px !important;
  }
  /deep/ .el-container{
    background-color: #eff6fb !important;
  }

</style>
