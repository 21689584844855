<template>
    <div id="market">
        <h1 class="title">院管项目顾客满意度调查评价表（集团分管领导）</h1>
                <el-table
                        :data="unitData"
                        border
                        size="small"
                        height="83%"
                        style="width: 100%;font-size: 16px"
                        :row-class-name="tableRowClassName"
                        @cell-mouse-enter="handleCellEnter"
                        @cell-mouse-leave="handleCellLeave"
                >
                    <el-table-column
                            type="index"
                            label="序号"
                            align="center"
                            width="50">
                    </el-table-column>
                    <el-table-column
                            prop="unitName"
                            width="200"
                            align="center"
                            label="分院名">
                    </el-table-column>
                    <el-table-column
                            prop="totalScore"
                            label="总分"
                            sortable
                            align="center"
                            width="60">
                    </el-table-column>
                    <el-table-column
                            prop="oneScore"
                            width="150"
                            align="center"
                            label="单位总体评价(20)">
                        <template slot-scope="scope">
                            <el-input class="item__input" v-model="scope.row.oneScore" placeholder="" onkeyup="value=value.replace(/^|[^\d]+/g,'').replace(/^0{1,}/g,'') " oninput="if(value<=0){value=0} if(value>=20){value=20}"></el-input>
                            <div  class="txt">{{scope.row.oneScore}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="twoScore"
                            label="各单位是否按计划准时提交成果，成果资料是否完整，重要节点工作是否及时(20)"
                            align="center"
                            width="220">
                        <template slot-scope="scope">
                            <el-input class="item__input" v-model="scope.row.twoScore" placeholder="" onkeyup="value=value.replace(/^|[^\d]+/g,'').replace(/^0{1,}/g,'') " oninput="if(value<=0){value=0} if(value>=20){value=20}"></el-input>
                            <div  class="txt">{{scope.row.twoScore}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="threeScore"
                            label="负责单位应及时向分管领导汇报项目情况，报送领导所需材料，项目汇报不及时、呈送材料质量不高未能按照领导意图所需提交的出现一次扣1分(10)"
                            align="center"
                            width="300">
                        <template slot-scope="scope">
                            <el-input class="item__input" v-model="scope.row.threeScore" placeholder="" onkeyup="value=value.replace(/^|[^\d]+/g,'').replace(/^0{1,}/g,'') " oninput="if(value<=0){value=0} if(value>=10){value=10}"></el-input>
                            <div  class="txt">{{scope.row.threeScore}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="fourScore"
                            label="各专业单位之间是否积极沟通、有效配合，是否存在集团分管领导需要协调的情况，酌情扣1-3分(10)"
                            align="center"
                            width="210">
                        <template slot-scope="scope">
                            <el-input class="item__input" v-model="scope.row.fourScore" placeholder="" onkeyup="value=value.replace(/^|[^\d]+/g,'').replace(/^0{1,}/g,'') " oninput="if(value<=0){value=0} if(value>=10){value=10}"></el-input>
                            <div  class="txt">{{scope.row.fourScore}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="fiveScore"
                            label="做好项目服务的及时性和持续性，服务项目解决问题的能力，现场投诉处理是否及时妥当，处理意见是否满意(20)"
                            align="center"
                            width="230">
                        <template slot-scope="scope">
                            <el-input class="item__input" v-model="scope.row.fiveScore" placeholder="" onkeyup="value=value.replace(/^|[^\d]+/g,'').replace(/^0{1,}/g,'') " oninput="if(value<=0){value=0} if(value>=20){value=20}"></el-input>
                            <div  class="txt">{{scope.row.fiveScore}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="sixScore"
                            label="是否收到业主口头抱怨投诉，每次扣2分(10)"
                            align="center"
                            width="150">
                        <template slot-scope="scope">
                            <el-input class="item__input" v-model="scope.row.sixScore" placeholder="" onkeyup="value=value.replace(/^|[^\d]+/g,'').replace(/^0{1,}/g,'') " oninput="if(value<=0){value=0} if(value>=10){value=10}"></el-input>
                            <div  class="txt">{{scope.row.sixScore}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="sevenScore"
                            label="内部配合问题，主诉单位应以书面形式报告，并附相关佐证材料报市场经营部，出现一次责任单位扣1分(10)"
                            align="center"
                            width="220">
                        <template slot-scope="scope">
                            <el-input class="item__input" v-model="scope.row.sevenScore" placeholder="" onkeyup="value=value.replace(/^|[^\d]+/g,'').replace(/^0{1,}/g,'') " oninput="if(value<=0){value=0} if(value>=10){value=10}"></el-input>
                            <div class="txt">{{scope.row.sevenScore}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            label="操作"
                            >
                        <template slot-scope="scope">
                            <el-button
                                    size="mini"
                                    :disabled="scope.row.totalScore>0"
                                    @click="handleEdit(scope.$index, scope.row)">保存</el-button>
                        </template>
                    </el-table-column>
                </el-table>
        <p class="mark">注：建议单项得分90%为满意，70%（含70%）为一般，70%以下为不满意，有扣分依据的单项除外。</p>
    </div>

</template>

<script>

    export default {
        name: "appraise",
        data () {
            return {
                unit:'测绘地理信息分院',
                unit2: [
                    {prop: "unit3",label: "四川分院", width: 150},
                    {prop: "unit4",label: "环境工程院", width: 150},
                    {prop: "unit5",label: "公路分院", width: 150}
                ],
                unitData:[],
                proName:'',
                // 表格数据
                editProp: ['oneScore','twoScore','threeScore','fourScore', 'fiveScore', 'sixScore', 'sevenScore']
            }
        },
        methods: {
            async getScoreData(){
                const {data:res}= await this.$http.get('/v1.0/unit/findAll')
                if(res.status!=='3000'){
                    return this.$message.error('获取部门列表失败！'+res.message)
                }
                const {data:res2}= await this.$http.get('/v1.0/satisfaction/lead/findAll')
                if(res2.status!=='3000'){
                    return this.$message.error('获取评分失败！'+res2.message)
                }
                this.unitData=res.data;
                for(let i in this.unitData){
                    for(let j in res2.data){
                        if(this.unitData[i].unitCode==res2.data[j].unitCode){
                            console.log(this.unitData[i].unitCode)
                            this.unitData[i]=res2.data[j]
                        }
                    }
                }
                console.log(this.unitData)
            },

            tableRowClassName({row, rowIndex}) {
                if (row.totalScore) {
                    return 'success-row';
                } else {
                    return 'warning-row';
                }
                return '';
            },
            async handleEdit(index,row){
                console.log(row)
                if(row.oneScore && row.twoScore  && row.threeScore  && row.fourScore  && row.fiveScore  && row.sixScore  && row.sevenScore){
                    row['customerTel']= window.sessionStorage.getItem("customerTel");
                    row['customerName']= window.sessionStorage.getItem("name");
                    const {data:res}= await this.$http.post('/v1.0/satisfaction/lead/save',row)
                    if(res.status!=='6000'){
                        return this.$message.error('保存失败！'+res.message)
                    }
                    this.$message.success('保存成功！')
                    this.getScoreData();
                }else {
                    return this.$message.warning('保存失败！有选项内容没有进行评分！')
                }

            },
            handleDelete(index,row){

            },
            getUserList(){

            },
            /** 鼠标移入cell */
            handleCellEnter(row, column, cell, event) {
                const property = column.property
                if (this.editProp.includes(property) && !row[property]) {
                    cell.querySelector('.item__input').style.display = 'block'
                    cell.querySelector('.txt').style.display = 'none'
                }
            },
            /** 鼠标移出cell */
            handleCellLeave(row, column, cell, event) {
                const property = column.property
                if (this.editProp.includes(property)) {
                    cell.querySelector('.item__input').style.display = 'none'
                    cell.querySelector('.txt').style.display = 'block'
                }

                if (row[property]) {

                }
            }
        },
        created() {
            this.getScoreData();
        },

    }
</script>

<style scoped lang="less">
    #market{
        height: 100vh;
        padding: 0;
        margin: 0;
    }
    .title{
        text-align: center;
        font-size: 24px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    /deep/ .el-input__inner{
        padding: 0 5px !important;
        height: 30px !important;
    }
    /deep/ .el-table .warning-row {
        background: oldlace;
    }

    /deep/ .el-table .success-row {
    }
    /deep/ .el-table thead .is-group th {
        text-align: center;
    }

    .item__input{
        display: none;
    }
    .mark{
        color: red;
        font-size: 19px;
        margin-top: 6px;
    }
</style>
