import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../components/login.vue'
import Home from '../components/Home.vue'
import homepage from '../components/homepage.vue'
import tousu from '../components/tousu.vue'
import appraise from '../components/appraise.vue'
import market from '../components/market.vue'
import weihu from '../components/weihu.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
    meta: {title:'顾客服务管理平台-登录'}
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {title:'顾客服务管理平台-登录'}
  },
  {
    path: '/homePage',
    component: Home,
    redirect: '/home',
    children: [
      {
        path: '/home',
        components: {
          'main': homepage,
        },
        meta: {title:'顾客服务管理平台-首页'}
      },
    ]
  },
  {
    path: '/tousuhome',
    component: Home,
    redirect: '/tousu',
    children: [
      {
        path: '/tousu',
        components: {
          'main': tousu,
        },
        meta: {title:'顾客服务管理平台-投诉满意度管理'}
      },
    ]
  },
  {
    path: '/appraisehome',
    component: Home,
    redirect: '/appraise',
    children: [
      {
        path: '/appraise',
        components: {
          'main': appraise,
        },
        meta: {title:'顾客服务管理平台-领导顾客满意度评价'}
      },
    ]
  },
  {
    path: '/shichanghome',
    component: Home,
    redirect: '/appraiseofmarket',
    children: [
      {
        path: '/appraiseofmarket',
        components: {
          'main': market,
        },
        meta: {title:'顾客服务管理平台-市场部顾客满意度评价'}
      },
    ]
  },
  {
    path: '/weihuhome',
    component: Home,
    redirect: '/weihu',
    children: [
      {
        path: '/weihu',
        components: {
          'main': weihu,
        },
        meta: {title:'顾客服务管理平台-维护'}
      },
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
