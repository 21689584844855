<template>
    <div class="el-header">
      <div id="titleimg">
      </div>
      <div id="topbar">
        <el-menu
                :default-active="activeIndex2"
                class="el-menu-demo"
                mode="horizontal"
                text-color="#fff"
                @select="handleSelect"
                active-text-color="#fff">
          <el-menu-item index="/home">首页</el-menu-item>
          <el-menu-item index="/tousu" style="width: 25% !important;" v-show="isshow">投诉与满意度管理</el-menu-item>
           <el-menu-item index="/appraise" style="width: 25% !important;"  v-show="orderShow">满意度评价(领导)</el-menu-item>
            <el-menu-item index="/appraiseofmarket" style="width: 25% !important;" v-show="marketShow">满意度评价(市场部)</el-menu-item>
          <el-menu-item index="/weihu" v-show="isshow">系统维护</el-menu-item>
        </el-menu>
      </div>
        <div id="selectYear">
            <el-date-picker
                    v-model="curStartYear"
                    placeholder="请选择查询年份"
                    type="year"
                    :picker-options="startPickerOptions"
                    format="yyyy"
                    value-format="yyyy"
                    @change="handleChangeStart"
                    :default-value="defaultValue"
            >
            </el-date-picker>
        </div>
      <div id="yonghu">
        <div style="height: 100%;float: left"  v-show="isshow">
            <el-badge :value=geshu class="item">
          <img src="../assets/img/em1.png" style="margin-top: 60%;height: 25%;cursor: pointer;" @click="tiao">
            </el-badge>
        </div>
         <div style="height: 100%;float: left;margin-left: 12%" >
          <!-- <img src="../assets/img/touxiang.png" style="height: 72%;margin-top:16%; "/>-->
         </div>
         <div style="height: 100%;float: left;margin-left: 5%;padding-top: 6%">
             <el-dropdown>
                 <span  style="font-size: 1.3rem;color: #a6abb3">{{name}}<i class="el-icon-arrow-down el-icon--right"></i></span>
                 <el-dropdown-menu slot="dropdown">
                     <el-dropdown-item><span @click="editPwd">修改密码</span></el-dropdown-item>
                     <el-dropdown-item @click="zhuxiao"><span  @click="zhuxiao">注销用户</span></el-dropdown-item>
                     <el-dropdown-item>版本信息</el-dropdown-item>
                 </el-dropdown-menu>
             </el-dropdown>
         </div>
      </div>
        <el-dialog
                title="修改用户密码"
                :visible.sync="editdialogVisible"
                width="25%"
                center
        >
            <el-form ref="editFormRef" :model="editForm" label-width="95px" :rules="editFormRules">
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="name" disabled></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="password"  >
                    <el-input type="password" v-model="editForm.password" autocomplete="off"> </el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="checkPass"  >
                    <el-input type="password" v-model="editForm.checkPass" autocomplete="off"> </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
     <el-button round type="goon" size="medium" @click="editdialogVisible = false">取 消</el-button>
    <el-button round type="goon" size="medium" @click="edit" >确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
    import  eventBus from '../assets/js/eventBus'
  export default {
    name: 'daohang',
    data() {
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.editForm.password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
      return {
        activeIndex2: '/home',
        meau:[],
        name:'',
        isshow:true,
          orderShow:false,
          marketShow:false,
        editdialogVisible:false,
          editForm:{
              password:'',
              checkPass:''
          },
          editFormRules:{
              password:[{required:true,message:'请输入新密码',trigger:'blur'}],
              checkPass: [{ validator: validatePass2, trigger: 'blur' },{required:true,message:'请输入确认密码',trigger:'blur'}],
          },
          geshu:0,
          toyear: '1',
          options:[{
              value: '1',
              label: '今年'
          },{
              value: '2',
              label: '昨年'
          },],
          curStartYear:'2023',
          defaultValue:'2023',
          startPickerOptions:{
              disabledDate(time) {
                  return time.getTime() > Date.now()-8.64e7;
              },
          }

      };
    },
    created(){
        this.name=window.sessionStorage.getItem('name');
        if(window.sessionStorage.getItem('roleId')==1){
            this.isshow=true
        }else {
            this.isshow=false
        }
        if(window.sessionStorage.getItem('ownerRole')==1){
            this.marketShow=true
            this.isshow=true
        }else if(window.sessionStorage.getItem('ownerRole')==2){
            this.isshow=false
            this.orderShow=true
        }else {
            this.marketShow=false
                this.orderShow=false
        }
        this.tousujisuan();
    },
    methods: {
        handleSelect(key, keyPath) {
            this.activeIndex2=key
            this.$router.push(this.activeIndex2)
        },
        zhuxiao(){
            window.sessionStorage.removeItem('token')
            this.$router.push('./login')
        },
        editPwd(){
            this.editdialogVisible=true;
        },
        async edit(){
            const {data:res}= await this.$http.put('/v1.0/user/password?id='+window.sessionStorage.getItem('userid')+'&password='+this.editForm.checkPass)
            if(res.status!='6000'){
                return this.$message.error('修改密码失败！'+res.message)
            }
            this.$message.success(res.message);
            this.editdialogVisible=false;
        },
        tiao(){
            this.activeIndex2='/tousu'
            this.$router.push({ path: '/tousu'});
        },
       async tousujisuan(){
            const {data:res}= await this.$http.get('/v1.0/complainEvent/queryCountIsNotRead')
           if(res.status!='3000'){
               this.geshu=0
           }
             this.geshu=res.data;
        },
      /*  changeYear(row){
            eventBus.$emit('toyear',row)
        },*/
        handleChangeStart(row){
            eventBus.$emit('toyear',row)
        }
    },
      mounted(){

          this.activeIndex2 = this.$route.path
      },
     watch:{
         $route: {
             handler:  function (val, oldVal) {
                 this.curStartYear='2023'
                 this.defaultValue='2023'
                 this.tousujisuan()
             },
             deep: true
         }
      }
  }
</script>

<style lang="less" scoped>
  .el-header {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0;
    background-color: red;
 }
  #titleimg{
    width: 24%;
    background:url("../assets/img/daotitle3.png") no-repeat;
    background-size: 100% 100%;
    margin-left: 2%;
    height: 60%;
    float: left;
  }
  #topbar{
    width: 45%;
    float: left;
    height: 100%;
    text-align: center;
  }
  #selectYear{
      width: 10%;
      float: left;
  }
  #yonghu{
    width: 14%;
    float: left;
    height: 100%;
  }
  /deep/ .el-menu{
    height: 100%;
    border-bottom: 0 !important;
  }
  /deep/ .el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: none;
    color: #4b4e53 !important;
    font-weight: bold;
    font-size: 1.3rem !important;
    line-height: 305%;
  }
  /deep/ .el-menu--horizontal>.el-menu-item{
    height: 100%;
    color: #a6abb3 !important;
    font-size: 1.3rem !important;
    line-height: 310%;
    width: 10% !important;
  }
  li{
    text-align: center;
  }
  /deep/ .el-dialog{
    border-radius: 8px;
  }
  /deep/ .el-dialog__header {
    //background: -webkit-linear-gradient(left, #3ee8c2, #25e4a3);
    border-radius: 8px 8px 0 0 ;
  }
 /deep/ .el-badge__content.is-fixed{
     top:15px !important;
 }
/deep/ .el-date-editor{
    width: 200px !important;
}
</style>
