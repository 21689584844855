<template>
  <div id="weihu">
      <div id="whleft">
          <div style="width: 100%;height: 5%">
              <div style="background-color: #4E90EA;width: 1.5%;height: 55%;margin-left: 9%;margin-top: 4%;float: left">
              </div>
              <div style="float: left;margin-left: 3.5%;height: 95%">
                  <p style="font-size: 1.35rem;color: #4b4e53;font-weight: bold;margin-top: 13%;">维护列表</p>
              </div>
          </div>
          <div style="width: 100%;height: 90%">
              <button class="btn " slot="reference" @click="yonghuweihu" :class="{active:current==1}">用户信息维护</button>
              <button class="btn" slot="reference" @click="guanliweihu" :class="{active:current==2}">管理员维护</button>
              <button class="btn" slot="reference" @click="yezhuweihu" :class="{active:current==4}">业主维护</button>
            <button class="btn" slot="reference" @click="gongshiweihu" :class="{active:current==3}">考核公式维护</button>
          </div>
      </div>
      <div id="whright">
              <div style="width: 100%;height: 5%">
                  <div style="background-color: #4E90EA;width: 0.45%;height: 55%;margin-left: 2.5%;margin-top: 0.9%;float: left">
                  </div>
                  <div style="float: left;margin-left: 1.3%;height: 95%">
                      <p style="font-size: 1.35rem;color: #4b4e53;font-weight: bold;margin-top: 7.5%;">{{title}}</p>
                  </div>
              </div>
              <div v-show="yonghuchoose" style="width: 100%;height: 90%;margin-top: 1%" class="yhwh" >
                  <el-form ref="form" :model="userform" label-width="13%" label-position="left">
                      <el-form-item label="姓名：" style="width: 90%;">
                          <el-input v-model="userform.userName" style=""></el-input>
                      </el-form-item>
                    <!--  <el-form-item label="部门：" style="width: 90%;">
                          <el-select v-model="userform.unitId" filterable placeholder="选择人员的部门（可搜索）" style="width: 100%">
                              <el-option
                                      v-for="item in unitData"
                                      :key="item.unitId"
                                      :label="item.unitName"
                                      :value="item.unitId">
                              </el-option>
                          </el-select>
                      </el-form-item>-->
                      <el-form-item label="账号：" style="width: 90%;">
                          <el-input v-model="userform.loginTel" style=""></el-input>
                      </el-form-item>
                      <!--<el-form-item label="密码：" style="width: 90%;">
                          <el-input v-model="userform.password" style="" show-password></el-input>
                      </el-form-item>
                      <el-form-item label="确认密码：" style="width: 90%;">
                          <el-input v-model="userform.checkpassword" style="" show-password></el-input>
                      </el-form-item>-->
                  </el-form>
                  <button class="queding" slot="reference" @click="saveuser" style="width: 7%;padding: 0;text-align: center;margin-left: 45%">修改</button>
              </div>
              <div v-show="guanlichoose" style="width: 100%;height: 90%;margin-top: 1%" class="yhwh" id="glywh">
              <el-form ref="addFormRef" :model="addForm" label-width="14%" label-position="left" :rules="addFormRule">
                  <el-form-item label="姓名：" style="width: 90%;"  prop="userName">
                      <el-input v-model="addForm.userName" style=""></el-input>
                  </el-form-item>
                  <el-form-item label="管理员：" style="width: 90%;" prop="roleId">
                      <el-select v-model="addForm.roleId" filterable placeholder="请选择是否是管理员" style="width: 100%" @change="guanli">
                          <el-option
                                  label="是"
                                  :value="1">
                          </el-option>
                          <el-option
                                  label="否"
                                  :value="2">
                          </el-option>
                      </el-select>
                  </el-form-item>
                  <el-form-item label="* 部门：" style="width: 90%;" prop="unitId" v-show="bumenshow">
                      <el-select v-model="addForm.unitId" filterable placeholder="请选择新增人员的部门（可搜索）" style="width: 100%">
                          <el-option
                                  v-for="item in unitData"
                                  :key="item.unitId"
                                  :label="item.unitName"
                                  :value="item.unitId">
                          </el-option>
                      </el-select>
                  </el-form-item>
                  <el-form-item label="登陆账号：" style="width: 90%;" prop="loginTel">
                      <el-input v-model="addForm.loginTel" style=""></el-input>
                  </el-form-item>
                  <el-form-item label="密码：" style="width: 90%;" prop="password" v-show="pwdshow">
                      <el-input v-model="addForm.password" style="" show-password></el-input>
                  </el-form-item>
                  <el-form-item label="确认密码：" style="width: 90%;" prop="qrpassword" v-show="pwdshow">
                      <el-input v-model="addForm.qrpassword" style="" show-password></el-input>
                  </el-form-item>
              </el-form>
              <button class="queding" v-show="quxiaoxiugai"  @click="quxiao" style="width: 7%;padding: 0;text-align: center;margin-left: 40%;">取消修改</button>
              <button class="queding" slot="reference" @click="addUser" style="width: 7%;padding: 0;text-align: center;margin-left:45%" :class="{xiugai:quxiaoxiugai}">{{addtype}}</button>
                  <div style="border: 1px solid #E1E5EE;width: 86%;margin-left: 4%;margin-top: 2%"></div>
                  <div style="width: 100%;height: 5%">
                      <div style="background-color: #4E90EA;width: 0.45%;height: 60%;margin-left: 2.5%;margin-top: 0.9%;float: left">
                      </div>
                      <div style="float: left;margin-left: 1.3%;height: 95%">
                          <p style="font-size: 1.35rem;color: #4b4e53;font-weight: bold;margin-top: 8%;">管理员列表</p>
                      </div>
                  </div>
                  <div style="width: 86%;height: 35%;margin-left: 4%;overflow: hidden;">
                      <el-table
                              :data="tableData"
                              stripe
                              height="100%"
                              style="width: 100%">
                          <el-table-column
                                  type="index"
                                  label="序号"
                                  min-width="2%">
                          </el-table-column>
                          <el-table-column
                                  prop="userName"
                                  label="姓名"
                                  sortable
                                  min-width="10%">
                          </el-table-column>
                          <el-table-column
                                  prop="unit.unitName"
                                  label="部门"
                                  sortable
                                  min-width="20%">
                          </el-table-column>
                          <el-table-column
                                  prop="loginTel"
                                  label="账号"
                                  min-width="15%">
                          </el-table-column>
                          <el-table-column
                                  label="操作"
                                  min-width="15%">
                              <template slot-scope="scope">
                                  <el-button type="primary" round size="mini" @click="editUser(scope.row.id)">编辑</el-button> <el-button type="danger" round size="mini"  @click="deleteUser(scope.row.id)">删除</el-button>
                              </template>
                          </el-table-column>
                      </el-table>
                  </div>
          </div>
              <div v-show="gongshichoose" id="gs" style="width: 100%;height: 90%;margin-top: 1%" class="yhwh" >
                  <el-form ref="gsformRef" :model="gsform" label-width="14%" label-position="left">
                      <el-form-item label="公式:" style="width: 90%;">
                          <el-input v-model="gsform.gs"></el-input>
                      </el-form-item>
                      <el-form-item label="注释:" style="width: 90%;">
                          <el-input type="textarea" v-model="gsform.desc"  :rows="5"></el-input>
                      </el-form-item>
                  </el-form>

          </div>
              <div v-show="yezhuchoose" style="width: 100%;height: 90%;margin-top: 1%" class="yhwh" id="yezhuDiv">
              <el-form ref="yezhuRef" :model="yezhuaddForm" label-width="14%" label-position="left" :rules="yezhuRule">
                  <el-form-item label="姓名：" style="width: 90%;"  prop="name">
                      <el-input v-model="yezhuaddForm.name" style=""></el-input>
                  </el-form-item>
                  <el-form-item label="手机号：" style="width: 90%;" prop="phone">
                      <el-input v-model="yezhuaddForm.phone" style=""></el-input>
                  </el-form-item>
                 <!-- <el-form-item label="微信号：" style="width: 90%;" prop="wxid">
                      <el-input v-model="yezhuaddForm.wxid" style="" ></el-input>
                  </el-form-item>-->
                  <el-form-item label="单位名称：" style="width: 90%;" prop="unit">
                      <el-input v-model="yezhuaddForm.unit" style="" ></el-input>
                  </el-form-item>
              </el-form>
              <button class="queding" v-show="quxiaoxiugaiyezhu"  @click="quxiaoyezhu" style="width: 7%;padding: 0;text-align: center;margin-left: 40%;">取消修改</button>
              <button class="queding" slot="reference" @click="addYezhu" style="width: 7%;padding: 0;text-align: center;margin-left:45%" :class="{xiugai:quxiaoxiugaiyezhu}">{{addtypeyezhu}}</button>
              <div style="border: 1px solid #E1E5EE;width: 86%;margin-left: 4%;margin-top: 2%"></div>
              <div style="width: 100%;height: 5%">
                  <div style="background-color: #4E90EA;width: 0.45%;height: 60%;margin-left: 2.5%;margin-top: 0.7%;float: left">
                  </div>
                  <div style="float: left;margin-left: 1.3%;height: 95%;display: flex">
                      <p style="font-size: 1.35rem;color: #4b4e53;font-weight: bold;margin-top: 1%;width: 140px">业主列表</p>
                      <el-input placeholder="请输入查询内容" v-model="yezhuName" class="input-with-select" clearable @clear="getYezhu" style="width: 250px">
                          <el-button  type="goon" round slot="append" icon="el-icon-search" @click="getUserByname" ></el-button>
                      </el-input>
<!--
                      <el-input placeholder="请输入手机号查询" v-model="yezhuphone" class="input-with-select" clearable @clear="getYezhu" style="width: 250px;margin-left: 40px">
                          <el-button  type="goon" round slot="append" icon="el-icon-search" @click="getUserByphone" ></el-button>
                      </el-input>-->
                  </div>
              </div>
              <div style="width: 86%;height:400px;margin-left: 4%;overflow: hidden;">
                  <el-table
                          :data="yezhuData"
                          stripe
                          height="100%"
                          style="width: 100%">
                      <el-table-column
                              type="index"
                              label="序号"
                              min-width="2%">
                      </el-table-column>
                      <el-table-column
                              prop="name"
                              label="姓名"
                              sortable
                              min-width="10%">
                      </el-table-column>
                      <el-table-column
                              prop="phone"
                              label="手机号"
                              min-width="10%">
                      </el-table-column>
                      <el-table-column
                              prop="wxid"
                              label="微信号"
                              min-width="10%">
                      </el-table-column>
                      <el-table-column
                              prop="unit"
                              label="单位名称"
                              sortable
                              min-width="15%">
                      </el-table-column>
                      <el-table-column
                              prop="activity"
                              label="是否是黑名单"
                              sortable
                              min-width="8%">
                          <template slot-scope="scope">
                           <span v-if="scope.row.activity">是</span>
                              <span v-else>否</span>
                          </template>
                      </el-table-column>
                      <el-table-column
                              label="操作"
                              min-width="15%">
                          <template slot-scope="scope">
                              <el-button type="primary" round size="mini" @click="editYezhu(scope.row.id)">编辑</el-button>
                              <el-button type="danger" round size="mini"  @click="deleteYezhu(scope.row.id)">删除</el-button>
                              <el-button v-if="!scope.row.activity"  type="danger" round size="mini"  @click="addheimingdan(scope.row.id)">添加黑名单</el-button>
                              <el-button v-else  type="info" round size="mini"  @click="removehmd(scope.row.id)">移除黑名单</el-button>
                          </template>
                      </el-table-column>
                  </el-table>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
    export default {
        name: "weihu",
        data(){
            var validatePass2 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.addForm.password) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            var validatorPhone = function (rule, value, callback) {
                if (value === '') {
                    callback(new Error('手机号不能为空'))
                } else if (!/^1\d{10}$/.test(value)) {
                    callback(new Error('手机号格式错误'))
                } else {
                    callback()
                }
            }
            return{
                current:1,
                title:'用户信息维护',
                yonghuchoose:true,
                guanlichoose:false,
                gongshichoose:false,
                yezhuchoose:false,
                userform: {
                    "id": 1,
                    "loginTel": "18782404136",
                    "userName": "longyuhuan",
                    "roleId": 1,
                    "role": {
                        "roleId": 1,
                        "roleName": "管理员"
                    },
                    "unitId": 59,
                    "unit": {
                        "unitId": 59,
                        "unitCode": "OD120720083901",
                        "unitName": "测绘地理信息分院"
                    },
                    password:'',
                    checkpassword:'',
                },
                addtype:'新增',
                addtypeyezhu:'新增',
                quxiaoxiugai:false,
                quxiaoxiugaiyezhu:false,
                addForm:{
                    userName:'',
                    loginTel:'',
                    password:'',
                    qrpassword:'',
                    unitId:'',
                    roleId:null,
                },
                addFormRule:{
                    userName:[{required:true,message:'请输入用户名',trigger:'blur'},{min:1,max:20,message:"用户名的长度再1-20个字符之间",trigger:'blur'}],
                    password:[{required:true,message:'请输入密码',trigger:'blur'},{min:3,max:20,message:"密码的长度再3-20个字符之间",trigger:'blur'}],
                    qrpassword: [{ validator: validatePass2, trigger: 'blur' },{required:true,message:'请输入密码',trigger:'blur'}],
                    loginTel:[{required:true,validator: validatorPhone,trigger:'blur'}],
                    roleId:[{required:true,message:'请选择是否是管理员',trigger:'blur'}],
                    //unitId:[{required:true,message:'请选择部门',trigger:'blur'}],
                },
                yezhuRule:{
                    name:[{required:true,message:'请输入姓名',trigger:'blur'}],
                    phone:[{required:true,validator: validatorPhone,trigger:'blur'}],
                  //  wxid:[{required:true,message:'请输入微信号',trigger:'blur'}],
                    unit:[{required:true,message:'请输入单位名称',trigger:'blur'}],
                },
                unitData:[],
                tableData: [],
                bumenshow:true,
                pwdshow:true,
                gsform:{
                    gs:'ā-（100-ā）/3≤服务评价得分fn≤ā+（100-ā）/3',
                    desc:'每个项目的顾客满意度调查评价总分值为100分，项目承担单位的满意度得分（以ɑn表示）为该单位所有评价项目得分的算术平均值。\n' +
                        '顾客投诉按照造成影响的严重程度分为抱怨和投诉，顾客抱怨一次扣1分，投诉一次分别扣2、3、5分，一个考核期内，被投诉单位扣分全年累计（以bn表示）。\n' +
                        '服务评价得分（以fn表示）为项目承担单位的满意度得分ɑn减去顾客投诉扣分全年累计值bn。\n' +
                        '集团公司满意度得分（以ā表示）为所有单位服务评价得分fn的算术平均值。\n' +
                        '服务评价得分fn低于集团公司满意度区间时，项目承担单位服务评价系数为0.95；得分介于区间内时，系数为1.0；得分高于区间时，系数为1.05。'
                },
                yezhuaddForm:{
                    id:'',
                    phone:'',
                    name:'',
                    unit:'',
                    wxid:''
                },
                yezhuData:[],
                yezhuName:'',
                yezhuphone:'',
            }
        },
        methods:{
            yonghuweihu(){
                this.current=1;
                this.title='用户信息维护';
                this.yonghuchoose=true;
                this.guanlichoose=false;
                this.gongshichoose=false;
                this.yezhuchoose=false;
            },
           async guanliweihu(){
                this.current=2;
                this.title='管理员维护';
                this.yonghuchoose=false;
               this.gongshichoose=false;
                this.guanlichoose=true;
               this.yezhuchoose=false;
                const {data:res}= await this.$http.get('/v1.0/user/findAllUsers');
               if(res.status!=='3000'){
                   return this.$message.error('获取管理员列表失败！'+res.message)
               }
                this.tableData=res.data
            },
            gongshiweihu(){
                this.current=3;
                this.title='考核公式维护'
                this.gongshichoose=true;
                this.yonghuchoose=false;
                this.guanlichoose=false;
                this.yezhuchoose=false;
            },
           async yezhuweihu(){
                this.current=4;
                this.title='业主维护'
                this.yezhuchoose=true;
                this.gongshichoose=false;
                this.yonghuchoose=false;
                this.guanlichoose=false;
                const {data:res}= await this.$http.get('/v1.0/owner/find/ownerAll');
                if(res.status!=='3000'){
                    return this.$message.error('获取业主列表失败！'+res.message)
                }
                this.yezhuData=res.data
            },
            async getUserInfo(){
                const {data:res}= await this.$http.get('/v1.0/user/'+window.sessionStorage.getItem('userid'))
                if(res.status!=='3000'){
                    return this.$message.error('获取个人信息失败！'+res.message)
                }
                this.userform=res.data;
            },
            async getUnitData(){
                const {data:res}= await this.$http.get('/v1.0/unit/findAll')
                if(res.status!=='3000'){
                    return this.$message.error('获取部门列表失败！'+res.message)
                }
                this.unitData=res.data;
            },
            async addUser(){
                if(this.addtype=='修改'){
                    const {data:res}= await this.$http.post('/v1.0/user/saveUser?unitId='+this.addForm.unitId+'&loginTel='+this.addForm.loginTel+'&userName='+this.addForm.userName+'&password='+this.addForm.password+'&roleId='+this.addForm.roleId+'&id='+this.addForm.id)
                    if(res.status!=="6000"){
                        console.log(res)
                        return this.$message.error('修改用户失败！'+res.message)
                    }
                    this.$message.success('修改用户成功！');
                    this.guanliweihu();
                }else {
                    this.$refs.addFormRef.validate(async valid =>{
                        if(!valid)
                            return
                        if(this.addtype=='新增'){
                            if(this.addForm.unitId=='' && this.addForm.roleId!=1){
                                return this.$message.error('部门未填写！！')
                            }
                            const {data:res}= await this.$http.post('/v1.0/user/saveUser?unitId='+this.addForm.unitId+'&loginTel='+this.addForm.loginTel+'&userName='+this.addForm.userName+'&password='+this.addForm.password+'&roleId='+this.addForm.roleId)
                            if(res.status!=="6000"){
                                console.log(res)
                                return this.$message.error('添加用户失败！'+res.message)
                            }
                            this.$message.success('添加用户成功！');
                        }else if(this.addtype=='修改'){

                        }
                        this.guanliweihu();
                    })
                }
            },
            async editUser(id){
                const {data:res}= await this.$http.get('/v1.0/user/'+id)
                if(res.status!=='3000'){
                    return this.$message.error('获取管理员信息失败！'+res.message)
                }
                this.addForm=res.data;

                this.pwdshow=false;
                this.addtype='修改';
                this.quxiaoxiugai=true;
            },

            quxiao(){
                this.addtype='新增';
                this.quxiaoxiugai=false;
                this.pwdshow=true;
                this.$refs.addFormRef.resetFields();
            },
            async addYezhu(){
                if(this.addtypeyezhu=='修改'){
                    this.$refs.yezhuRef.validate(async valid =>{
                        if(!valid)
                            return
                    const {data:res}= await this.$http.post('/v1.0/owner/save/saveOwner?phone='+this.yezhuaddForm.phone+'&id='+this.yezhuaddForm.id + '&name='+this.yezhuaddForm.name+'&wxid='+this.yezhuaddForm.wxid+'&unit='+this.yezhuaddForm.unit)
                    if(res.status!=="6000"){
                        console.log(res)
                        return this.$message.error('修改业主失败！'+res.message)
                    }
                    this.$message.success('修改业主成功！');
                    this.yezhuweihu();
                    })
                }else {
                    this.$refs.yezhuRef.validate(async valid =>{
                        if(!valid)
                            return
                        if(this.addtypeyezhu=='新增'){
                            const {data:res}= await this.$http.post('/v1.0/owner/save/saveOwner?phone='+this.yezhuaddForm.phone+'&name='+this.yezhuaddForm.name+'&wxid='+this.yezhuaddForm.wxid+'&unit='+this.yezhuaddForm.unit)
                            if(res.status!=="6000"){
                                return this.$message.error('添加业主失败！'+res.message)
                            }
                            this.$message.success('添加业主成功！');
                        }else if(this.addtypeyezhu=='修改'){

                        }
                        this.yezhuweihu();
                    })
                }
            },
            async editYezhu(id){
                const {data:res}= await this.$http.get('/v1.0/owner/find/ownerById/'+id)
                if(res.status!=='3000'){
                    return this.$message.error('获取业主信息失败！'+res.message)
                }
                this.yezhuaddForm=res.data;
                this.addtypeyezhu='修改';
                this.quxiaoxiugaiyezhu=true;
            },
            async deleteYezhu(id){
                const {data:res}= await this.$http.delete('/v1.0/owner/delete/ownerById/'+id)
                if(res.status!=="4000"){
                    return this.$message.error('删除业主失败！'+res.message)
                }
                this.$message.success('删除业主成功！');
                this.yezhuweihu();
            },
            async addheimingdan(id){
                const {data:res}= await this.$http.put('/v1.0/owner/update/ownerActivity/'+id)
                if(res.status!=="6000"){
                    return this.$message.error('添加黑名单失败！'+res.message)
                }
                this.$message.success('添加黑名单成功！');
                this.yezhuweihu();
            },
            async removehmd(id){
                const {data:res}= await this.$http.put('/v1.0/owner/relieve/ownerActivity/'+id)
                if(res.status!=="6000"){
                    return this.$message.error('移除黑名单失败！'+res.message)
                }
                this.$message.success('移除黑名单成功！');
                this.yezhuweihu();
            },
            quxiaoyezhu(){
                this.addtypeyezhu='新增';
                this.quxiaoxiugaiyezhu=false;
                this.$refs.yezhuRef.resetFields();
            },
            async handleCurrentChange(val) {
               /* const {data:res}= await this.$http.get('/v1.0/user/'+val.id)
                if(res.status!=='3000'){
                    return this.$message.error('获取管理员信息失败！'+res.message)
                }
                this.addForm=res.data;*/
                //this.addtype='修改';
            },
            async saveuser(){
                console.log(3)
                const {data:res}= await this.$http.post('/v1.0/user/saveUser?loginTel='+this.userform.loginTel+'&userName='+this.userform.userName+'&password='+this.userform.password+'&roleId='+this.userform.roleId+'&id='+this.userform.id)
                if(res.status!=="6000"){
                    return this.$message.error('修改用户失败！'+res.message)
                }
                this.$message.success('修改用户成功！');
                this.getUserInfo();
            },
            async deleteUser(id){
                const {data:res}= await this.$http.delete('/v1.0/user/deleteUser/'+id)
                console.log(res)
                if(res.status!=="4000"){
                    return this.$message.error('删除用户失败！'+res.message)
                }
                this.$message.success('删除用户成功！');
                this.guanliweihu();
            },
            guanli(data){
                if(data==1){
                    this.bumenshow=false
                }else {
                    this.bumenshow=true
                }

            },
            async getUserByname(){
                const {data:res}= await this.$http.get('/v1.0/owner/find/findOwnerByString/'+this.yezhuName)
                if(res.status=='3000'){
                    this.yezhuData=res.data;
                }
            },
            async getUserByphone(){
                const {data:res}= await this.$http.get('/v1.0/owner/find/findOwnerByString/'+this.yezhuphone)
                if(res.status=='3000'){
                    this.yezhuData=res.data;
                }
            },
            async getYezhu(){
                const {data:res}= await this.$http.get('/v1.0/owner/find/ownerAll');
                if(res.status=='3000'){
                    this.yezhuData=res.data
                }

            }

        },
        created() {
            this.getUserInfo();
            this.getUnitData();
        }
    }
</script>

<style scoped lang="less">
  #weihu{
      width: 100%;
      height: 99.5%;
      background: #f5f7ff;
      border-top: 1px solid #E5E8FB;
      display: flex;
  }
    #whleft{
        margin-left: 2%;
        width: 21%;
        height: 94%;
        margin-top: 1%;
        background: #fff;
        border-radius: 15px;
        box-shadow: 0px 2px 10px rgba(172,196,254,0.36);
        border: 1px solid #E9EFFF;
    }
    #whright{
        margin-left: 1%;
        width: 74%;
        height: 94%;
        margin-top: 1%;
        background: #fff;
        border-radius: 15px;
        box-shadow: 0px 2px 10px rgba(172,196,254,0.36);
        border: 1px solid #E9EFFF;
    }
  .btn {
      border-radius: 0.5rem;
      width: 80%;
      border: 1px solid var(--blue);
      cursor: pointer;
      font-size: 1rem;
      font-weight: bold;
      letter-spacing: 0.1rem;
      padding: 0.1rem 3rem;
      text-transform: uppercase;
      text-align: left;
      color: #9dada7;
      transition: transform 80ms ease-in;
      margin-top: 2.5%;
      transition: 0.8s !important;
      height: 2.6rem;
      margin-left: 10%;
      background: #f4f7fe;
  }
  .active{
      background-image: linear-gradient(90deg, #51A1FA 0%, #4b88e3 80%) !important;
      color: white !important;
      border: 0 !important;
  }
  .btn:hover {
      border-radius: 4rem;
  }
  .yhwh{
     /deep/ .el-form-item__label{
          font-size: 16px !important;
         padding-left: 5%;
      }
      /deep/ .el-input__inner{
          border:0;
          background-color:#F4F7FE !important;
          font-size: 16px !important;
      }
  }
  .queding{
      border-radius: 1rem;
      height: 1.8rem;
      cursor: pointer;
      border: 1px solid var(--blue);
      background-image: linear-gradient(90deg, #51A1FA 0%, #4b88e3 80%) !important;
      color: white !important;
  }
  /deep/ .cell{
      text-align: center;
  }
  /deep/ .el-table__empty-text{
      line-height:40px
  }
  /deep/ .el-table__empty-block{
      min-height: 40px;
  }
  /deep/ .el-table__body-wrapper {
      /*height: 200px; !* 滚动条整体高 必须项 *!*/
      border-right: none;
      overflow-y: scroll;/* overflow-y为了不出现水平滚动条*/
  }
  /deep/ .el-dialog--center .el-dialog__body{
      padding: 10px;
  }
  /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width:6px;/* 滚动条的宽高 必须项 */
      height: 5px;
  }

  /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #bfcbd9;/* 滚动条的宽 */
      border-radius: 3px;
  }
#gs{
 /deep/ .el-textarea__inner{
     border: 0;
     background-color: #F4F7FE !important;
     font-size: 15px !important;
 }
}
  .xiugai{
      margin-left: 5% !important;
  }
  *:focus { outline: none; }
   #yezhuDiv{
       /deep/ .el-table__body-wrapper{
           height: 80% !important;
       }
   }
    /deep/ .el-input-group__append{
        border:1px solid #DCDFE6 !important;
    }
   #glywh {
       /deep/  .el-form-item__label{
          color: red;
      }
  }
</style>
