<template>
 <div id="market">
     <el-card class="box-card">
         <div slot="header" class="clearfix">
             <el-row :gutter="24">
                 <el-col :span="4">
                     <el-input placeholder="请输入项目名查询" v-model="proName" class="input-with-select" clearable @clear="getScoreData">
                         <el-button  type="goon"  slot="append" icon="el-icon-search" @click="getUserList" ></el-button>
                     </el-input>
                 </el-col>
                 <el-col :span="18">
                     <el-select v-model="unitCode" clearable  filterable placeholder="请选择分院" @change="changeunit">
                         <el-option
                                 v-for="item in unitData"
                                 :key="item.unitCode"
                                 :label="item.unitName"
                                 :value="item.unitCode">
                         </el-option>
                     </el-select>
                 </el-col>
                 <el-col :span="2" class="btnright"><el-button  @click="dialogVisible = true" type="primary"  disabled>添加项目</el-button></el-col>
             </el-row>
         </div>
         <div  class="serverTable">
             <el-table
                     :data="tableData"
                     border
                     height="100%"
                     style="width: 100%;font-size: 16px"
                     :row-class-name="tableRowClassName"
             >
                 <el-table-column
                         type="index"
                         label="序号"
                         align="center"
                         width="70">
                 </el-table-column>
                 <el-table-column
                         prop="projectName"
                         width="400"
                         align="center"
                         label="项目名称">
                 </el-table-column>
                 <el-table-column
                         prop="projectCreateDate"
                         label="项目创建时间"
                         align="center"
                         width="130">
                 </el-table-column>
                 <el-table-column
                         prop="unitName"
                         width="230"
                         align="center"
                         label="技术牵头单位">
                 </el-table-column>
                 <el-table-column
                         prop="marketSatisfaction"
                         label="总分"
                         sortable
                         align="center"
                         width="100">
                 </el-table-column>
                 <el-table-column label="配合单位" align="center">
                     <template slot="header" slot-scope="scope">
                         <span>配合单位</span>
                     </template>
                     <el-table-column
                             v-for="(col,index) in unit2"
                             :label="col.label"
                             :key="index"
                             :prop="col.prop"
                             align="center"
                             :width=col.width
                     >
                         <template slot-scope="scope">
                             <el-input class="item__input"  v-model="scope.row[col.prop]" placeholder=""  onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'') " oninput="if(value<=0){value=0} if(value>=20){value=20}"></el-input>
                             <div class="txt">{{scope.row[col.prop]}}</div>
                         </template>
                     </el-table-column>
                 </el-table-column>
                <!-- <el-table-column
                         prop="oneScore"
                         label="生产效率"
                         align="center"
                         width="120">
                 </el-table-column>
                 <el-table-column
                         prop="twoScore"
                         label="各分院之间的工作配合"
                         align="center"
                         width="120">
                 </el-table-column>
                 <el-table-column
                         prop="threeScore"
                         label="工作态度"
                         align="center"
                         width="120">
                 </el-table-column>
                 <el-table-column
                         prop="fourScore"
                         label="工作主动性"
                         align="center"
                         width="120">
                 </el-table-column>
                 <el-table-column
                         prop="fiveScore"
                         label="服务能力"
                         align="center"
                         width="120">
                 </el-table-column>-->
                 <el-table-column
                         align="center"
                         label="操作">
                     <template slot-scope="scope">
                         <el-button
                                 size="mini"
                                 :disabled="scope.row.marketSatisfaction>0"
                                 @click="handleEdit(scope.$index, scope.row)">打分</el-button>
                         <el-button
                                 size="mini"
                                 type="danger"
                                 :disabled="!scope.row.marketSatisfaction>0"
                                 @click="handleDelete(scope.$index, scope.row)" >重置</el-button>
                     </template>
                 </el-table-column>
             </el-table>
         </div>
     </el-card>
     <el-dialog title="院管项目顾客满意度调查评价表（市场经营部）" :visible.sync="dialogTableVisible" width="94%" top="3vh" center>
         <p class="protitle">工程名称：{{scoreProName}}</p>
         <el-table :data="gridData"
                   style="width: 100%;font-size: 15px"
                   size="small"
                   @cell-mouse-enter="handleCellEnter"
                   @cell-mouse-leave="handleCellLeave"
                   show-summary>
             <el-table-column
                     type="index"
                     label="序号"
                     align="center"
                     width="70">
             </el-table-column>
             <el-table-column
                     prop="content"
                     label="调查内容"
                     align="center"
                     width="150">
             </el-table-column>
             <el-table-column
                     prop="target"
                     label="具体指标"
                     align="center"
                     width="630">
                 <template slot-scope="scope">
                     <span v-html="scope.row.target" style="text-align: left !important;"></span>
                 </template>
             </el-table-column>
             <el-table-column
                     prop="num"
                     label="标准分值"
                     align="center"
                     width="120">
             </el-table-column>
             <el-table-column label="技术牵头单位"  align="center">
                 <el-table-column
                         prop="relnum"
                         :label="unit"
                         align="center"
                         width="150">
                     <template slot-scope="scope">
                         <el-input class="item__input"  v-model="scope.row.relnum" placeholder=""  onkeyup="value=value.replace(/^|[^\d]+/g,'').replace(/^0{1,}/g,'') " oninput="if(value<=0){value=0} if(value>=20){value=20}"></el-input>
                         <div class="txt">{{scope.row.relnum}}</div>
                     </template>
                 </el-table-column>
             </el-table-column>
                 <el-table-column label="配合单位" align="center">
                     <template slot="header" slot-scope="scope">
                         <span>配合单位</span> &nbsp;&nbsp;&nbsp;
                         <i class="el-icon-circle-plus-outline" style="cursor: pointer"></i>
                     </template>
                     <el-table-column
                             v-for="(col,index) in unit2"
                             :label="col.label"
                             :key="index"
                             :prop="col.prop"
                             align="center"
                             width="150"
                     >
                         <template slot-scope="scope">
                             <el-input class="item__input"  v-model="scope.row[col.prop]" placeholder=""  onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'') " oninput="if(value<=0){value=0} if(value>=20){value=20}"></el-input>
                             <div class="txt">{{scope.row[col.prop]}}</div>
                         </template>
                 </el-table-column>
             </el-table-column>
         </el-table>
         <span>提出合理化建议：</span><el-input
             type="textarea"
             :rows="1"
             class="advise"
             style=""
             placeholder="请输入内容"
             v-model="advise">
     </el-input>
         <p style="margin-bottom: 0" class="mark">注：1.建议各打分人员平时做好记录，扣分应有依据。</p>
         <p style="text-indent:2em;margin-top: 0;margin-bottom: 0" class="mark">    2.建议单项得分90%为满意，70%（含70%）为一般，70%以下为不满意，有扣分依据的单项除外。</p>
         <div slot="footer" class="dialog-footer">
             <el-button @click="dialogTableVisible = false">取 消</el-button>
             <el-button type="primary" @click="saveScore">保存</el-button>
         </div>
     </el-dialog>
 </div>
</template>

<script>
    import  eventBus from '../assets/js/eventBus'
    export default {
        name: "pingjia",
        data () {
            return {
                dialogVisible:false,
                dialogTableVisible:false,
                unit:'',
                unit2: [
                    {prop: "unit3",label: "暂定", width: 150},
                    {prop: "unit3",label: "总分", width: 100},
                    {prop: "unit4",label: "暂定", width: 150},
                    {prop: "unit5",label: "总分", width: 100},
                    {prop: "unit6",label: "暂定", width: 150},
                    {prop: "unit5",label: "总分", width: 100},
                ],
                unitData:[],
                unitCode:'OD120719172601',
                year:'2021-1-1',
                proName:'',
                scoreProName:'',
                advise:'',
                // 表格数据
                tableData: [{
                    name:'测试项目1',
                    unit:'测绘地理信息分院',
                    unit2:'环境工程院',
                    num:90
                },{
                    name:'测试项目12',
                    unit:'测绘地理信息分院',
                    unit2:'',
                    num:null
                },{
                    name:'测试项目133',
                    unit:'测绘地理信息分院',
                    unit2:'',
                    num:90
                },{
                    name:'测试项目144',
                    unit:'测绘地理信息分院',
                    unit2:'',
                    num:null
                },{
                    name:'测试项目155',
                    unit:'测绘地理信息分院',
                    unit2:'',
                    num:null
                }],
                gridData:[{
                    content:'生产效率',
                    target:'项目服务过程中牵头单位是否对项目进度合理安排，配合单位是否按进度计划执行？是否按计划准时提交成果，成果资料是否完整，重要节点工作是否及时？  <br> 各节点超时但没有影响后续工作进展的每次扣1分，影响到集团层面整体工作每次安排的扣3分，影响到业主层面整体工作安排并造成不良影响的扣5分。',
                    num:20,
                    relnum:null,
                    unit3:null,
                    unit4:null,
                    unit5:null,
                    unit6:null,
                },{
                    content:'各分院之间的工作配合',
                    target:'各专业单位之间工作内容发生变化时沟通的及时性、准确性、有效性；牵头单位组织技术分析、讨论会的及时性，配合单位参加技术分析、讨论会及时性，响应技术牵头单位的配合度。<br>' +
                        '如有配合不佳、沟通不及时，造成其他合作单位返工、重复劳动等情况的，根据影响程度每次扣1-3分。',
                    num:20,
                    relnum:null,
                    unit3:null,
                    unit4:null,
                    unit5:null,
                    unit6:null,
                },{
                    content:'工作态度',
                    target:'各单位应无条件接受市场部正常的工作安排，确实有困难的应书面说明情况，并提出合理建议。无故推诿工作/不作为/影响工作整体进展的每次扣2分。<br>' +
                        '项目服务人员应保持对业主良好的工作态度，实行首问负责制的一站式服务，杜绝出现事不关己、业主需求不落实的情况，出现一次扣2分。',
                    num:20,
                    relnum:null,
                    unit3:null,
                    unit4:null,
                    unit5:null,
                    unit6:null,
                },{
                    content:'工作主动性',
                    target:'对本项目情况应积极了解、主动提出合理化建议，应保持积极主动、完整履行工作职责。不持续保持积极主动性、未完整履行工作职责的扣1分；不作为、消极、隐瞒实情的扣2分。',
                    num:20,
                    relnum:null,
                    unit3:null,
                    unit4:null,
                    unit5:null,
                    unit6:null,
                },{
                    content:'服务能力',
                    target:'能配合市场部共同维护市场声誉和形象，确保与业主保持良好关系。做好项目服务的及时性和持续性，服务项目解决问题的能力，现场投诉处理是否及时妥当，处理意见是否满意。<br>' +
                        '市场部接到业主口头抱怨投诉的每次扣1分；在业主提出设代需求时，未能及时协调项目组按要求完成各项设代服务工作的每次扣2分；在发生设计变更后，未能较好地处理现场情况，影响项目进度的每次扣2分。',
                    num:20,
                    relnum:null,
                    unit3:null,
                    unit4:null,
                    unit5:null,
                    unit6:null,
                }],
                editProp: ['unit3', 'unit4', 'unit5', 'unit6','relnum'],
                saveForm:{},
            }
        },
        methods: {
            async getUnitData(){
                const {data:res}= await this.$http.get('/v1.0/unit/findAll')
                if(res.status!=='3000'){
                    return this.$message.error('获取部门列表失败！'+res.message)
                }
                this.unitData=res.data;
            },
            async getScoreData(){
                const {data:res}= await this.$http.get('/v1.0/project/findAllByUnitCode/'+this.unitCode+'/'+this.year)
                if(res.status!=='3000'){
                    return this.$message.error('获取项目信息失败！'+res.message)
                }
                this.tableData=res.data

            },
            async getUserList(){

            },
            tableRowClassName({row, rowIndex}) {
                if (row.marketSatisfaction) {
                    return 'success-row';
                } else {
                    return 'warning-row';
                }
                return '';
            },
            async handleEdit(index,row){
                console.log(row)
                this.saveForm=row
                this.unit=row.unitName
                 this.scoreProName=row.projectName
                const {data:res}= await this.$http.get('/v1.0/satisfaction/market/findMarketSatisfactionByProjectCode?projectCode='+row.projectCode)
                if(res.data){
                    this.gridData[0].relnum=res.data.oneScore
                    this.gridData[1].relnum=res.data.twoScore
                    this.gridData[2].relnum=res.data.threeScore
                    this.gridData[3].relnum=res.data.fourScore
                    this.gridData[4].relnum=res.data.fiveScore
                }
                this.dialogTableVisible=true
            },
            async saveScore(){
                if(this.gridData[0].relnum  && this.gridData[1].relnum  &&this.gridData[2].relnum  &&this.gridData[3].relnum  &&this.gridData[4].relnum ){
                    let form= [{
                        "customerName":  window.sessionStorage.getItem("name"),
                        "customerTel": window.sessionStorage.getItem("customerTel"),
                        "fiveScore": this.gridData[4].relnum,
                        "fourScore": this.gridData[3].relnum,
                        'scoreDate':this.year,
                        "oneScore": this.gridData[0].relnum,
                        "opinion": this.advise,
                        "projectCode": this.saveForm.projectCode,
                        "projectName": this.saveForm.projectName,
                        "threeScore": this.gridData[2].relnum,
                        "twoScore": this.gridData[1].relnum,
                        "unitCode": this.saveForm.unitCode,
                        "unitName": this.saveForm.unitName,
                        "unitType":0
                    }]

                    const {data:res}= await this.$http.post('/v1.0/satisfaction/market/save',form)
                    if(res.status!=='6000'){
                        return this.$message.error('保存失败！'+res.message)
                    }
                    this.$message.success('保存成功！')
                    this.dialogTableVisible=false
                    this.getScoreData();
                }else {
                    return this.$message.warning('保存失败！有选项内容没有进行评分！')
                }

            },
           async handleDelete(index,row){
                console.log(row)
                const {data:res}= await this.$http.delete('/v1.0/satisfaction/deleteMarketSatisfaction?id='+row.marketSatisfactionId)
                if(res.status!=="4000"){
                    return this.$message.error('删除评分失败！'+res.message)
                }
                this.$message.success('删除评分成功！');
                this.getScoreData();
            },
            changeunit(data){
                console.log(data)
                this.getScoreData();
            },
            /** 鼠标移入cell */
            handleCellEnter(row, column, cell, event) {
                const property = column.property
                if (this.editProp.includes(property)) {
                    cell.querySelector('.item__input').style.display = 'block'
                    cell.querySelector('.txt').style.display = 'none'
                }
            },
            /** 鼠标移出cell */
            handleCellLeave(row, column, cell, event) {
                console.log(row)
                console.log(column)
                const property = column.property
                console.log(row[property])
                if (this.editProp.includes(property)&&row[property]) {
                    cell.querySelector('.item__input').style.display = 'none'
                    cell.querySelector('.txt').style.display = 'block'
                }
            },
        },
        created() {
            this.getUnitData();
            this.getScoreData();
        },
        mounted() {
            this.$nextTick(() => {
                eventBus.$on('toyear',(message)=>{
                    console.log(message)
                    this.year=message+'-1-1'
                    this.getScoreData();
                })
            });
        },
    }
</script>

<style scoped lang="less">
    #market{
        height: 100%;
        padding: 0;
        margin: 0;
    }
    /deep/ .el-card{
        color: black;
        height: calc(100% - 2px);
    }
    /deep/ .el-card__header{
        padding: 6px 20px;
    }
    /deep/ .el-card__body{
        padding: 10px 20px;
    }
    .serverTable{
        position: absolute;
        width: 97.8%;
        padding: 0%;
        /*height: 100%;*/
        height: calc(100% - 150px);

    }
   /deep/ .el-table .warning-row {
        background: oldlace;
    }

   /deep/ .el-table .success-row {
    }
    /deep/ .el-table thead .is-group th {
text-align: center;
    }
    .protitle{
        margin-top: 1px;
        margin-bottom: 4px;
        font-size: 18px;
        color: red;
    }

    /deep/ .el-dialog__body{
      padding: 0 20px 0px 20px !important;
    }
    /deep/ .el-dialog__header{
        padding: 10px 10px 0 10px
    }
    /deep/ .el-dialog__title{
        font-size: 22px;
    }
    .item__input{
        display: none;
    }
    .advise{
        width: 91%;height: 24px;
        margin-top: 4px;
    }
    .mark{
        color: red;
        font-size: 19px;
    }
</style>
