import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import axios from 'axios'
/*import 'lib-flexible'*/

Vue.config.productionTip = false

const service = axios.create({
  baseURL:'/api',
  withCredentials:true
})
service.interceptors.request.use(config=>{
    config.headers.token=window.sessionStorage.getItem('token');
    return config
})
Vue.prototype.$http = service;

/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
