<template>
<div id="all">
    <div id="head">
    <div id="topleft" class="divkuang">
        <span style="color: red;font-size: 40px;position: absolute;margin-left: 18%;margin-top: 0.2%;text-align: right">{{tousuall}}</span>
        <div style="width: 100%;height: 40%">
        <div style="background-color: #4E90EA;width: 1.5%;height: 40%;margin-left: 10%;margin-top: 5%;float: left">
        </div>
        <div style="float: left;margin-left: 4%;height: 95%">
            <p style="font-size: 1.35rem;color: #4b4e53;font-weight: bold;margin-top: 10%;">顾客投诉数量统计</p>
        </div>
        </div>
        <div style="width: 100%;height: 20%;display: flex;margin-top: 2%">
            <div style="width: 65%;padding-left: 15%">
                <span style="color: #a6abb3;font-size: 1.1rem">已处理投诉数：{{tousunum}}</span>
            </div>
            <div style="color: #a6abb3;width: 25%" >{{yichulizhanbi}}%</div>
        </div>
        <div style="width: 82%;height: 5%;background-color: #D6D6D6;margin-left: 10%;margin-top: 2%;border-radius: 1rem">
            <div style="height: 100%;background-color: red;border-radius: 1rem"  :style="'width:' + yichulizhanbi + '%'"></div>
        </div>
    </div>
    <div id="conleft" class="divkuang">
        <span style="color: red;font-size: 40px;position: absolute;margin-left: 18%;margin-top: 0.2%;text-align: right">{{manyiall}}</span>
        <div style="width: 100%;height: 40%">
            <div style="background-color: #4E90EA;width: 1.5%;height: 40%;margin-left: 10%;margin-top: 5%;float: left">
            </div>
            <div style="float: left;margin-left: 4%;height: 95%">
                <p style="font-size: 1.35rem;color: #4b4e53;font-weight: bold;margin-top: 8%;">顾客满意度数量统计</p>
            </div>
        </div>
        <div style="width: 100%;height: 20%;display: flex;margin-top: 2%">
            <div style="width: 65%;padding-left: 15%">
                <span style="color: #a6abb3;font-size: 1.1rem">无抱怨：{{baoyuan}}</span>
            </div>
            <div style="color: #a6abb3;width: 25%">{{baoyuanzhanbi}}%</div>
        </div>
        <div style="width: 82%;height: 5%;background-color: #D6D6D6;margin-left: 10%;margin-top: 2%;border-radius: 1rem">
            <div style="height: 100%;background-color: red;border-radius: 1rem" :style="'width:' + baoyuanzhanbi + '%'"></div>
        </div>
    </div>
     <!--<div id="conleft2" class="divkuang">
            <span style="color: red;font-size: 40px;position: absolute;margin-left: 18%;margin-top: 0.2%;text-align: right">{{ordermanyiall}}</span>
            <div style="width: 100%;height: 40%">
                <div style="background-color: #4E90EA;width: 1.5%;height: 40%;margin-left: 10%;margin-top: 5%;float: left">
                </div>
                <div style="float: left;margin-left: 4%;height: 95%">
                    <p style="font-size: 1.35rem;color: #4b4e53;font-weight: bold;margin-top: 8%;">院内满意度数量统计</p>
                </div>
            </div>
            <div style="width: 100%;height: 20%;display: flex;margin-top: 2%">
                <div style="width: 65%;padding-left: 15%">
                    <span style="color: #a6abb3;font-size: 1.1rem">无抱怨：{{orderbaoyuan}}</span>
                </div>
                <div style="color: #a6abb3;width: 25%">{{orderbaoyuanzhanbi}}%</div>
            </div>
            <div style="width: 82%;height: 5%;background-color: #D6D6D6;margin-left: 10%;margin-top: 2%;border-radius: 1rem">
                <div style="height: 100%;background-color: red;border-radius: 1rem" :style="'width:' + orderbaoyuanzhanbi + '%'"></div>
            </div>
        </div>-->
    <div id="rightleft" class="divkuang" v-show="fuwushow">
        <div style="width: 100%;height: 40%">
            <div style="background-color: #4E90EA;width: 1.5%;height: 40%;margin-left: 10%;margin-top: 5%;float: left">
            </div>
            <div style="float: left;margin-left: 4%;height: 95%">
                <p style="font-size: 1.35rem;color: #4b4e53;font-weight: bold;margin-top: 12%;">服务评价系数</p>
            </div>
        </div>
        <div style="width: 100%;height: 20%;display: flex;margin-top: 3%">
            <div style="width: 45%;padding-left: 15%">
                <span style="color: #a6abb3;font-size: 1.1rem">得分：</span><span style="font-size: 1.4rem;color: red">{{fuwufen}}</span>
            </div>
            <div style="width: 45%;padding-left: 3%">
                <span style="color: #a6abb3;font-size: 1.1rem">系数：</span><span style="font-size: 1.4rem;color: blue">{{xishufen}}</span>
            </div>

        </div>
    </div>
    <div id="rightleft2" class="divkuang" v-show="!fuwushow">
            <div style="width: 100%;height: 40%">
                <div style="background-color: #4E90EA;width: 1.5%;height: 40%;margin-left: 10%;margin-top: 4%;float: left">
                </div>
                <div style="float: left;margin-left: 4%;height: 95%">
                    <p style="font-size: 1.35rem;color: #4b4e53;font-weight: bold;margin-top: 10.5%;">服务评价统计</p>
                </div>
            </div>
            <div style="width: 100%;height: 20%;display: flex;margin-top: 3%">
                <div style="width: 25%;padding-left: 15%">
                    <span style="color: #a6abb3;font-size: 1.1rem">0.95：</span>
                    <el-popover
                            placement="top-start"
                            trigger="hover"
                           >
                            <div v-for="item in fuwu0data"  class="text item">
                                {{ item }}
                            </div>
                        <span style="font-size: 1.4rem;color: red"  slot="reference">{{fuwu0}}</span>
                    </el-popover>

                </div>
                <div style="width: 25%;padding-left: 3%">
                    <span style="color: #a6abb3;font-size: 1.1rem">1.00：</span>
                    <el-popover
                            placement="top-start"
                            trigger="hover"
                    >
                        <div v-for="item in fuwu1data" class="text item">
                            {{ item }}
                        </div>
                        <span style="font-size: 1.4rem;color: blue"  slot="reference">{{fuwu1}}</span>
                    </el-popover>
                </div>
                <div style="width: 25%;padding-left: 3%">
                    <span style="color: #a6abb3;font-size: 1.1rem">1.05：</span>
                    <el-popover
                            placement="top-start"
                            trigger="hover"
                    >
                        <div v-for="item in fuwu2data"  class="text item">
                            {{ item }}
                        </div>
                        <span style="font-size: 1.4rem;color: green"  slot="reference">{{fuwu2}}</span>
                    </el-popover>
                </div>
            </div>
        </div>
    </div>
    <div id="content">
        <div id="cont" class="divkuang">
           <!-- <div style="width: 100%;height: 10%">
                <div style="background-color: #4E90EA;width: 0.5%;height: 60%;margin-left: 3.3%;margin-top: 1.5%;float: left">
                </div>
                <div style="float: left;margin-left: 1%;height: 95%">
                    <p style="font-size: 1.35rem;color: #4b4e53;font-weight: bold;margin-top: 12%;">分院分数统计图</p>
                </div>
                <div style="float: right;width: 18%;margin-top: 1%;height: 80%;margin-right: 3.5%" v-show="pingfenshow">
                    <div class="toolsbtn"  style="width: 30%;height: 80%;text-align:center; border-radius: 15px  0 0 15px;border: 2px solid #e2e9f2;color: #a6abb3;float: left;font-size: 15px;line-height: 170%;" :class="{activie:current==3}" @click="toolsfw">服务评分</div>
                    <div class="toolsbtn"  style="width:30%;height: 80%;text-align:center; border: 2px solid #e2e9f2;color: #a6abb3;float: left;border-right: 0;border-left: 0;font-size: 15px;line-height: 170%;" :class="{activie:current==2}" @click="toolsmyd">满意度</div>
                    <div class="toolsbtn"  style="width: 30%;height: 80%;text-align:center; border-radius: 0px  15px 15px 0px;border: 2px solid #e2e9f2;color: #a6abb3;float: left;font-size: 15px;line-height: 170%;" :class="{activie:current==1}" @click="toolsts">投诉</div>
                </div>
                <div style="float: right;width: 18%;margin-top: 1%;height: 80%;margin-right: 3.5%" v-show="!pingfenshow">
                    <div class="toolsbtn"  style="width: 30%;height: 80%;text-align:center; border-radius: 15px  0 0 15px;border: 2px solid #e2e9f2;color: #a6abb3;float: left;font-size: 15px;line-height: 170%;" :class="{activie:current==1}" @click="toolsts">投诉</div>
                    <div class="toolsbtn"  style="width:30%;height: 80%;text-align:center; border-radius: 0px  15px 15px 0px; border: 2px solid #e2e9f2;color: #a6abb3;float: left;border-right: 0;border-left: 0;font-size: 15px;line-height: 170%;" :class="{activie:current==2}" @click="toolsmyd">满意度</div>
                </div>
            </div>
            <div id="contechatrs" style="width: 100%;height: 90%"></div>-->
                <div style="width: 100%;height: 10%">
                    <div style="background-color: #4E90EA;width: 0.5%;height: 60%;margin-left: 3.3%;margin-top: 1.5%;float: left">
                    </div>
                    <div style="float: left;margin-left: 1%;height: 95%">
                        <p style="font-size: 1.35rem;color: #4b4e53;font-weight: bold;margin-top: 9%;">投诉/满意度整体统计</p>
                    </div>
                    <div style="float: left;width: 15%;margin-top: 1.1%;height: 80%;margin-left: 25%">
                        <div class="toolsbtn"  style="width: 45%;height: 80%;text-align:center; border-radius: 15px  0 0 15px;border: 2px solid #e2e9f2;color: #a6abb3;float: left;font-size: 15px;line-height: 170%;" :class="{activie:current3==1}" @click="tousutongji">投诉</div>
                        <div class="toolsbtn" style="width: 45%;height: 80%;text-align:center; border-radius: 0px  15px 15px 0px;border: 2px solid #e2e9f2;color: #a6abb3;float: left;font-size: 15px;line-height: 170%;" :class="{activie:current3==3}" @click="manyidutongji">满意度</div>
                    </div>
                </div>
                <div style="width: 100%;height: 88%;margin-top: 10px">
                    <div style="width: 55%; height: 100%;float: left">
                        <div style="width: 100%;height: 100%;" id="lefttongji" >
                        </div>
                    </div>
                    <div style="width: 45%; height: 100%;float: left">
                        <div style="width: 100%;height: 100%;" id="righttongji">
                        </div>
                    </div>
                </div>
        </div>
        <div id="right" class="divkuang">
            <div style="width: 100%;height: 8%">
                <div style="background-color: #4E90EA;width: 1.3%;height: 50%;margin-left: 9%;margin-top: 3.5%;float: left">
                </div>
                <div style="float: left;margin-left: 3.5%;height: 95%">
                    <p style="font-size: 1.35rem;color: #4b4e53;font-weight: bold;margin-top: 12.5%;">分院排名</p>
                </div>
            </div>
            <div  id="projSelect" style="height: calc(90% - 3px);overflow: hidden;" >
                <el-table
                        :data="paimingData"
                        stripe
                        height="100%"
                        style="width: 100%">
                    <el-table-column
                            type="index"
                            label="排名"
                            min-width="12%">
                    </el-table-column>
                    <el-table-column
                            prop="unitName"
                            label="单位名称"
                            min-width="60%">
                    </el-table-column>
                    <el-table-column
                            prop="serviceScore"
                            label="服务评价得分"
                            min-width="30%">
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>

    <div id="bottom" class="divkuang">
        <div style="width: 100%;height: 20%">
            <div style="background-color: #4E90EA;width: 0.35%;height: 44%;margin-left: 2.3%;margin-top: 1.2%;float: left">
            </div>
            <div style="float: left;margin-left: 1%;height: 85%">
                <p style="font-size: 1.35rem;color: #4b4e53;font-weight: bold;margin-top: 12%;">满意度数据统计</p>

            </div>
            <div style="float: left;margin-left: 6%;height: 85%">
                <el-button type="primary" round size="small" style="margin-top:22%;" @click="exportexcle">导出表格</el-button>
            </div>
            <div style="float: right;width: 13%;margin-top: 1%;height: 80%;margin-right: 2.5%">
                <div class="toolsbtn"  style="width: 50%;height: 65%;text-align:center; border-radius: 15px  0 0 15px;border: 2px solid #e2e9f2;color: #a6abb3;float: left;font-size: 15px;line-height: 170%;" :class="{activie:current2==0}" @click="feiEpcclick">非工程总承包</div>
                <div class="toolsbtn" style="width: 44%;height: 65%;text-align:center; border-radius: 0px  15px 15px 0px;border: 2px solid #e2e9f2;color: #a6abb3;float: left;font-size: 15px;line-height: 170%;" :class="{activie:current2==1}" @click="epcclcik">工程总承包</div>
            </div>
        </div>
        <div style="width: 100%; height: calc(75% - 5px);;overflow: hidden;">
            <div style="height: 100%;margin-left: 3%">
                <el-table
                        :data="epcData"
                        stripe
                        height="100%"
                        v-show="!epcshow"
                        style="width: 100%"
                >
                    <el-table-column
                            type="index"
                            label="序号"
                            min-width="2%"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="unitName"
                            label="分院名称"
                            min-width="10%">
                    </el-table-column>
                    <el-table-column
                            prop="totalAvgScore"
                            label="全院平均分"
                            min-width="6%">
                    </el-table-column>
                    <el-table-column
                            prop="avgScore"
                            label="满意度总平均分"
                            min-width="6%">
                    </el-table-column>
                    <el-table-column
                            prop="avgOverallScore"
                            label="总体评价平均分"
                            min-width="6%">
                    </el-table-column>
                    <el-table-column
                            prop="avgQualityScore"
                            label="质量控制平均分"
                            min-width="6%">
                    </el-table-column>
                    <el-table-column
                            prop="avgProcessScore"
                            label="进度控制平均分"
                            min-width="6%">
                    </el-table-column>
                    <el-table-column
                            prop="avgInvestmentScore"
                            label="投资控制平均分"
                            min-width="6%">
                    </el-table-column>
                    <el-table-column
                            prop="avgSecurityScore"
                            label="安全管理平均分"
                            min-width="6%">
                    </el-table-column>
                    <el-table-column
                            prop="avgContractScore"
                            label="合同管理平均分"
                            min-width="6%">
                    </el-table-column>
                    <el-table-column
                            prop="avgAttitudeScore"
                            label="业务能力工作态度平均分"
                            min-width="6%">
                    </el-table-column>
                    <el-table-column
                            prop="avgResponsibleScore"
                            label="负责人总体评价平均分"
                            min-width="6%">
                    </el-table-column>
                    <el-table-column
                            prop="avgCooperationScore"
                            label="再合作意愿平均分"
                            min-width="6%">
                    </el-table-column>
                    <el-table-column
                            prop="avgRecommendScore"
                            label="推荐意愿平均分"
                            min-width="6%">
                    </el-table-column>
                </el-table>
                <el-table
                        :data="feiepcData"
                        stripe
                        height="100%"
                        v-show="epcshow"
                        style="width: 100%"
                >
                    <el-table-column
                            type="index"
                            label="序号"
                            min-width="2%"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="unitName"
                            label="分院名称"
                            min-width="10%">
                    </el-table-column>
                    <el-table-column
                            prop="totalAvgScore"
                            label="全院平均分"
                            min-width="6%">
                    </el-table-column>
                    <el-table-column
                            prop="avgScore"
                            label="满意度总平均分"
                            min-width="6%">
                    </el-table-column>
                    <el-table-column
                            prop="avgOverallScore"
                            label="总体评价平均分"
                            min-width="6%">
                    </el-table-column>
                    <el-table-column
                            prop="avgProcessScore"
                            label="进度执行平均分"
                            min-width="6%">
                    </el-table-column>
                    <el-table-column
                            prop="avgTimelyScore"
                            label="及时持续平均分"
                            min-width="6%">
                    </el-table-column>
                    <el-table-column
                            prop="avgResponseScore"
                            label="配合度平均分"
                            min-width="6%">
                    </el-table-column>
                    <el-table-column
                            prop="avgAttitudeScore"
                            label="工作态度平均分"
                            min-width="6%">
                    </el-table-column>
                    <el-table-column
                            prop="avgComplainHandleScore"
                            label="投诉处理平均分"
                            min-width="6%">
                    </el-table-column>
                    <el-table-column
                            prop="avgReasonableScore"
                            label="合理适用平均分"
                            min-width="6%">
                    </el-table-column>
                    <el-table-column
                            prop="avgServiceScore"
                            label="现场服务平均分"
                            min-width="6%">
                    </el-table-column>
                    <el-table-column
                            prop="avgCooperationScore"
                            label="再合作意愿平均分"
                            min-width="6%">
                    </el-table-column>
                    <el-table-column
                            prop="avgRecommendScore"
                            label="推荐意愿平均分"
                            min-width="6%">
                    </el-table-column>

                </el-table>
            </div>
        </div>
    </div>
    <el-dialog
            title="投诉事件详情"
            :visible.sync="dialogVisibleTsxq"
            width="30%"
            center
            @close="dialogClose"
    >
        <el-form  ref="tousuFormRef" :model="tousuForm" label-width="15%" >
            <el-form-item label="项目名称" prop="projectName">
                <el-input v-model="tousuForm.projectName" disabled></el-input>
            </el-form-item>
            <el-form-item label="被投诉人" prop="complainedPerson" >
                <el-input v-model="tousuForm.complainedPerson" disabled></el-input>
            </el-form-item>
            <el-form-item label="被投诉单位" prop="complainedUnit" >
                <el-input v-model="tousuForm.complainedUnit" disabled></el-input>
            </el-form-item>
            <el-form-item label="投诉人" prop="complainPerson" >
                <el-input v-model="tousuForm.complainPerson" disabled></el-input>
            </el-form-item>
            <el-form-item label="投诉单位" prop="complainUnit" >
                <el-input v-model="tousuForm.complainUnit" disabled></el-input>
            </el-form-item>
            <el-form-item label="投诉内容" prop="complainContent">
                <el-input  type="textarea" :rows="4"  v-model="tousuForm.complainContent" disabled></el-input>
            </el-form-item>
            <el-form-item label="回复内容" prop="answer">
                <el-input  type="textarea" :rows="4" placeholder="请输入回复内容" v-model="tousuForm.answer" disabled></el-input>
            </el-form-item>
            <el-form-item label="回复日期" prop="answerDate">
                <el-date-picker
                        v-model="tousuForm.answerDate"
                        type="date"
                        disabled>
                </el-date-picker>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
    <el-button type="goon" round size="medium" @click="dialogVisibleTsxq = false">关 闭</el-button>
  </span>
    </el-dialog>
    <el-dialog
            title="满意度事件详情"
            :visible.sync="dialogVisibleMydxq"
            width="45%"
            center
            @close="dialogClose1"
    >
        <el-form :inline="true"  ref="manyiduFormRef" :model="manyiduForm" label-width="24%" >
            <el-form-item label="项目名称" prop="projectName" style="width: 48%">
                <el-input v-model="manyiduForm.projectName" disabled></el-input>
            </el-form-item>
            <el-form-item label="满意度总分" prop="totalScore" style="width: 48%">
                <el-input v-model="manyiduForm.totalScore" disabled></el-input>
            </el-form-item>
            <el-form-item label="项目总体评价" prop="overallScore" style="width: 48%">
                <el-input v-model="manyiduForm.overallScore" disabled></el-input>
            </el-form-item>
            <el-form-item label="项目质量控制" prop="qualityScore" style="width: 48%">
                <el-input v-model="manyiduForm.qualityScore" disabled></el-input>
            </el-form-item>
            <el-form-item label="项目进度控制" prop="processScore" style="width: 48%">
                <el-input v-model="manyiduForm.processScore" disabled></el-input>
            </el-form-item>
            <el-form-item label="项目投资控制" prop="investmentScore"style="width: 48%" >
                <el-input v-model="manyiduForm.investmentScore" disabled></el-input>
            </el-form-item>
            <el-form-item label="项目安全管理" prop="securityScore" style="width: 48%" >
                <el-input v-model="manyiduForm.securityScore" disabled></el-input>
            </el-form-item>
            <el-form-item label="项目合同管理" prop="contractScore" style="width: 48%">
                <el-input v-model="manyiduForm.contractScore" disabled></el-input>
            </el-form-item>
            <el-form-item label="能力工作态度" prop="attitudeScore" style="width: 48%">
                <el-input v-model="manyiduForm.attitudeScore" disabled></el-input>
            </el-form-item>
            <el-form-item label="总体评价" prop="responsibleScore" style="width: 48%">
                <el-input v-model="manyiduForm.responsibleScore" disabled></el-input>
            </el-form-item>
            <el-form-item label="再合作意愿" prop="cooperationScore" style="width: 48%">
                <el-input v-model="manyiduForm.cooperationScore" disabled></el-input>
            </el-form-item>
            <el-form-item label="推荐意愿" prop="recommendScore" style="width: 48%">
                <el-input v-model="manyiduForm.recommendScore" disabled></el-input>
            </el-form-item>
            <el-form-item label="填写人单位" prop="customerUnit" style="width: 48%">
                <el-input v-model="manyiduForm.customerUnit" disabled></el-input>
            </el-form-item>
            <el-form-item label="联系方式" prop="customerTel" style="width: 48%">
                <el-input v-model="manyiduForm.customerTel" disabled></el-input>
            </el-form-item>
            <el-form-item label="合理化建议" prop="opinion" style="width: 48%">
                <el-input  type="textarea" :rows="4"  v-model="manyiduForm.opinion" disabled></el-input>
            </el-form-item>
            <el-form-item label="填写日期" prop="scoreDate" style="width: 48%">
                <el-date-picker
                        v-model="manyiduForm.scoreDate"
                        type="date"
                        disabled>
                </el-date-picker>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
    <el-button type="goon" round size="medium" @click="dialogVisibleMydxq = false">关 闭</el-button>
  </span>
    </el-dialog>
    <el-dialog
            title="满意度事件详情"
            :visible.sync="dialogVisibleMydxq2"
            width="45%"
            center
            @close="dialogClose2"
    >
        <el-form :inline="true"  ref="manyiduFormRef2" :model="manyiduForm2" label-width="24%" >
            <el-form-item label="项目名称" prop="projectName" style="width: 48%">
                <el-input v-model="manyiduForm2.projectName" disabled></el-input>
            </el-form-item>
            <el-form-item label="满意度总分" prop="totalScore" style="width: 48%">
                <el-input v-model="manyiduForm2.totalScore" disabled></el-input>
            </el-form-item>
            <el-form-item label="项目总体评价" prop="overallScore" style="width: 48%">
                <el-input v-model="manyiduForm2.overallScore" disabled></el-input>
            </el-form-item>
            <el-form-item label="项目计划执行" prop="processScore" style="width: 48%">
                <el-input v-model="manyiduForm2.processScore" disabled></el-input>
            </el-form-item>
            <el-form-item label="及时性持续性" prop="timelyScore" style="width: 48%">
                <el-input v-model="manyiduForm2.timelyScore" disabled></el-input>
            </el-form-item>
            <el-form-item label="响应配合度" prop="responseScore"style="width: 48%" >
                <el-input v-model="manyiduForm2.responseScore" disabled></el-input>
            </el-form-item>
            <el-form-item label="工作态度" prop="attitudeScore" style="width: 48%" >
                <el-input v-model="manyiduForm2.attitudeScore" disabled></el-input>
            </el-form-item>
            <el-form-item label="投诉处理情况" prop="complainHandleScore" style="width: 48%">
                <el-input v-model="manyiduForm2.complainHandleScore" disabled></el-input>
            </el-form-item>
            <el-form-item label="合理适用性" prop="responseScore" style="width: 48%">
                <el-input v-model="manyiduForm2.responseScore" disabled></el-input>
            </el-form-item>
            <el-form-item label="现场服务" prop="serviceScore" style="width: 48%">
                <el-input v-model="manyiduForm2.serviceScore" disabled></el-input>
            </el-form-item>
            <el-form-item label="再合作意愿" prop="cooperationScore" style="width: 48%">
                <el-input v-model="manyiduForm2.cooperationScore" disabled></el-input>
            </el-form-item>
            <el-form-item label="推荐意愿" prop="recommendScore" style="width: 48%">
                <el-input v-model="manyiduForm2.recommendScore" disabled></el-input>
            </el-form-item>
            <el-form-item label="填写人单位" prop="customerUnit" style="width: 48%">
                <el-input v-model="manyiduForm2.customerUnit" disabled></el-input>
            </el-form-item>
            <el-form-item label="联系方式" prop="customerTel" style="width: 48%">
                <el-input v-model="manyiduForm2.customerTel" disabled></el-input>
            </el-form-item>
            <el-form-item label="合理化建议" prop="opinion" style="width: 48%">
                <el-input  type="textarea" :rows="4"  v-model="manyiduForm2.opinion" disabled></el-input>
            </el-form-item>
            <el-form-item label="填写日期" prop="scoreDate" style="width: 48%">
                <el-date-picker
                        v-model="manyiduForm2.scoreDate"
                        type="date"
                        disabled>
                </el-date-picker>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
    <el-button type="goon" round size="medium" @click="dialogVisibleMydxq2 = false">关 闭</el-button>
  </span>
    </el-dialog>
</div>
</template>

<script>
    import  eventBus from '../assets/js/eventBus'
    import * as echarts from 'echarts';
    export default {
        name: "homepage",
        data() {
            return {
                tousunum:null,
                yichulizhanbi:0,
                baoyuan:0,
                orderbaoyuan:0,
                baoyuanzhanbi:0,
                orderbaoyuanzhanbi:0,
                fenyuanData:[
                   /* {paiming:1,fenyuanname:'测绘分院',tousukoufen:0,manyidefen:100,baoyuancishu:0,fuwudefen:100,xiaoyixishu:1.0},
                    {paiming:1,fenyuanname:'测绘分院',tousukoufen:0,manyidefen:100,baoyuancishu:0,fuwudefen:100,xiaoyixishu:1.0},
                    {paiming:1,fenyuanname:'测绘分院',tousukoufen:0,manyidefen:100,baoyuancishu:0,fuwudefen:100,xiaoyixishu:1.0},
                    {paiming:1,fenyuanname:'测绘分院',tousukoufen:0,manyidefen:100,baoyuancishu:0,fuwudefen:100,xiaoyixishu:1.0},
                    {paiming:1,fenyuanname:'测绘分院',tousukoufen:0,manyidefen:100,baoyuancishu:0,fuwudefen:100,xiaoyixishu:1.0},
                    {paiming:1,fenyuanname:'测绘分院',tousukoufen:0,manyidefen:100,baoyuancishu:0,fuwudefen:100,xiaoyixishu:1.0},
                    {paiming:1,fenyuanname:'测绘分院',tousukoufen:0,manyidefen:100,baoyuancishu:0,fuwudefen:100,xiaoyixishu:1.0},
                    {paiming:1,fenyuanname:'测绘分院',tousukoufen:0,manyidefen:100,baoyuancishu:0,fuwudefen:100,xiaoyixishu:1.0},*/
                    ],
                manyiduX:[],
                manyiduY:[],
                tousuX:[],
                tousuY:[],
                fuwuX:[],
                fuwuY:[],
                current:2,
                current1:1,
                istousu:true,
                tousudata:[],
                manyidudata:{
                    exceptEpc:null,
                    epc:null
                },
                current2:0,
                epcshow:false,
                epcData:[],
                feiepcData:[],
                fuwushow:false,
                fuwu0:0,
                fuwu1:0,
                fuwu2:0,
                fuwu0data:[],
                fuwu1data:[],
                fuwu2data:[],
                fuwufen:0,
                xishufen:0,
                pingfenshow:true,
                dialogVisibleTsxq:false,
                dialogVisibleMydxq:false,
                dialogVisibleMydxq2:false,
                tousuall:0,
                manyiall:0,
                ordermanyiall:0,
                tousuForm:{
                    projectName: '',
                    complainedPerson: '',
                    complainedUnit: '',
                    complainPerson: '',
                    complainUnit: false,
                    complainContent: [],
                    answer: '',
                },
                manyiduForm:{
                    projectName: '',
                    complainedPerson: '',
                    complainedUnit: '',
                    complainPerson: '',
                    complainUnit: false,
                    complainContent: [],
                    answer: '',
                },
                manyiduForm2:{

                },
                selectyear:'2023-1-1',
                paimingData:[],
                tousuTitle1:'各单位投诉个数所在比例',
                tousuTitle2:'各区域投诉个数所在比例',
                current3:1,
            }
        },
        methods: {
            async tousushijian(){
               const {data:res}= await this.$http.get('/v1.0/statistics/queryComplainEventAndSatisfactionHandling')
               if(res.status!=='3000'){
                   return this.$message.error('获取投诉事件处理情况失败！'+res.message)
               }
               this.tousunum=res.data.complain.handledCount
               this.tousuall=res.data.complain.totalCount;
               this.yichulizhanbi=(res.data.complain.handledCount/res.data.complain.totalCount).toFixed(2)*100;
               this.manyiall=res.data.satisfaction.totalCount
               this.baoyuan=res.data.satisfaction.noComplainCount;
               this.baoyuanzhanbi=(res.data.satisfaction.noComplainCount/res.data.satisfaction.totalCount).toFixed(2)*100
           },
            async tousushijianlastyear(){
                const {data:res}= await this.$http.get('/v1.0/statisticsBeforeYear/queryComplainEventAndSatisfactionHandling?date='+this.selectyear)
                if(res.status!=='3000'){
                    return this.$message.error('获取投诉事件处理情况失败！'+res.message)
                }
                this.tousunum=res.data.complain.handledCount
                this.tousuall=res.data.complain.totalCount;
                this.yichulizhanbi=(res.data.complain.handledCount/res.data.complain.totalCount).toFixed(2)*100;
                this.manyiall=res.data.satisfaction.totalCount
                this.baoyuan=res.data.satisfaction.noComplainCount;
                this.baoyuanzhanbi=(res.data.satisfaction.noComplainCount/res.data.satisfaction.totalCount).toFixed(2)*100
            },
            async contont() {
               const {data:res}= await this.$http.get('/v1.0/statisticsBeforeYear/queryUnitCoefficient?date='+this.selectyear)
               if(res.status!=='3000'){
                   return this.$message.error('获取满意度统计失败！'+res.message)
               }
               this.manyiduX=[];
               this.manyiduY=[];
               if(window.sessionStorage.getItem("roleId")==1){
                   for(let i in res.data){
                       this.manyiduX.push(res.data[i].unitName)
                       this.manyiduY.push(res.data[i].satisfactionScore.toFixed(1))
                   }
                   this.pingfenshow=true
               }else {
                   this.pingfenshow=false
                   this.manyiduX.push(res.data.scoreCoefficient[0].unitName)
                   this.manyiduY.push(res.data.scoreCoefficient[0].satisfactionScore)
                   for(let i in res.data.exceptEpcSatisfactionScore){
                       this.manyiduX.push(res.data.exceptEpcSatisfactionScore[i].projectName)
                       this.manyiduY.push(res.data.exceptEpcSatisfactionScore[i].avgScore.toFixed(1))
                   }
                   for(let j in res.data.epcSatisfactionScore){
                       this.manyiduX.push(res.data.epcSatisfactionScore[j].projectName)
                       this.manyiduY.push(res.data.epcSatisfactionScore[j].avgScore.toFixed(1))
                   }

               }
               var jishu=0;var jishux=0;

               for(let d in this.manyiduY){
                   if(Number(this.manyiduY[d])!=0){
                       jishu++
                       jishux+=Number(this.manyiduY[d])
                   }
               }
                var chartDom = document.getElementById('contechatrs');
                var myChart = echarts.init(chartDom);
                var option;
                option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data:this.manyiduX
                    },
                    yAxis: {
                        type: 'value'
                    },
                    grid:{
                        left:"80px",
                        right:"60px",
                        bottom:'45px'
                    },
                    series: [{
                        data: this.manyiduY,
                        name:'满意度平均分',
                        type: 'bar',
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        },
                        barWidth: 26,
                        label: {
                            show: true,
                            position: 'insideBottom',
                            color: '#fff'
                        },
                        markLine: {
                            data: [
                                {yAxis: (jishux/jishu).toFixed(1), name: '平均值'},
                            ],
                        },
                        itemStyle: {
                            normal: {
                                barBorderRadius: [30, 30, 0, 0],
                                color: new echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        {offset: 0, color: '#51a1fa'},
                                        {offset: 0.5, color: '#5097f1'},
                                        {offset: 1, color: '#4b88e3'}
                                    ]
                                ),
                            },
                            emphasis: {
                                barBorderRadius: [30, 30, 0, 0],
                                color: new echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        {offset: 0, color: '#2378f7'},
                                        {offset: 0.7, color: '#2378f7'},
                                        {offset: 1, color: '#83bff6'}
                                    ]
                                )
                            },
                        },

                    }],
                    dataZoom: [
                        {
                            type: "slider",
                            show: true,
                            xAxisIndex: [0],
                            start: 0,
                            end:100,
                            textStyle:{
                                color:"#ccd7d7"
                            }
                        },{
                            type: 'inside',
                            start: 0,
                            end: 100
                        },
                    ]
                };
                myChart.setOption(option);
                window.addEventListener("resize", function () {
                    myChart.resize();
                });
            },
            async toolsts(){
                this.current=1;
                const {data:res}= await this.$http.get('/v1.0/statisticsBeforeYear/queryUnitCoefficient?date='+this.selectyear)
                if(res.status!=='3000'){
                    return this.$message.error('获取各单位投诉次数失败！'+res.message)
                }
                this.tousuX=[];
                this.tousuY=[];
                if(window.sessionStorage.getItem("roleId")==1){
                    for(let i in res.data){
                        this.tousuX.push(res.data[i].unitName)
                        this.tousuY.push(res.data[i].complainEventCount)
                    }
                }else {
                    this.tousuX.push(res.data.scoreCoefficient[0].unitName)
                    this.tousuY.push(res.data.scoreCoefficient[0].complainEventCount)
                    for(let i in res.data.projectComplainCount){
                        this.tousuX.push(res.data.projectComplainCount[i].projectName)
                        this.tousuY.push(res.data.projectComplainCount[i].count)
                    }
                }

                var chartDom = document.getElementById('contechatrs');
                var myChart = echarts.init(chartDom);
                var option;
                option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data:this.tousuX
                    },
                    yAxis: {
                        type: 'value'
                    },
                    grid:{
                        left:"80px",
                        right:"60px",
                        bottom:'45px'
                    },
                    series: [{
                        data: this.tousuY,
                        name:'被投诉次数',
                        type: 'bar',
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        },
                        barWidth: 26,
                        label: {
                            show: true,
                            position: 'insideBottom',
                            color: '#fff'
                        },
                        markLine: {
                            data: [
                                {type: 'average', name: '平均值'},
                            ],
                        },
                        itemStyle: {
                            normal: {
                                barBorderRadius: [30, 30, 0, 0],
                                color: new echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        {offset: 0, color: '#51a1fa'},
                                        {offset: 0.5, color: '#5097f1'},
                                        {offset: 1, color: '#4b88e3'}
                                    ]
                                ),
                            },
                            emphasis: {
                                barBorderRadius: [30, 30, 0, 0],
                                color: new echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        {offset: 0, color: '#2378f7'},
                                        {offset: 0.7, color: '#2378f7'},
                                        {offset: 1, color: '#83bff6'}
                                    ]
                                )
                            },
                        },

                    }],
                    dataZoom: [
                        {
                            type: "slider",
                            show: true,
                            xAxisIndex: [0],
                            start: 0,
                            end:100,
                            textStyle:{
                                color:"#ccd7d7"
                            }
                        },{
                            type: 'inside',
                            start: 0,
                            end: 100
                        },
                    ]
                };
                myChart.setOption(option);
                window.addEventListener("resize", function () {
                    myChart.resize();
                });
            },
            toolsmyd(){
                this.current=2;
                this.contont();
            },
            async toolsfw(){
                const {data:res}= await this.$http.get('/v1.0/statisticsBeforeYear/queryUnitCoefficient?date='+this.selectyear)
                if(res.status!=='3000'){
                    return this.$message.error('获取分院数据统计失败！'+res.message)
                }
                this.fuwuX=[];
                this.fuwuY=[];
                for(let i in res.data){
                    this.fuwuX.push(res.data[i].unitName)
                    this.fuwuY.push(res.data[i].serviceScore)
                }
                var chartDom = document.getElementById('contechatrs');
                var myChart = echarts.init(chartDom);
                var option;
                option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data:this.fuwuX
                    },
                    yAxis: {
                        type: 'value'
                    },
                    grid:{
                        left:"80px",
                        right:"60px",
                        bottom:'45px'
                    },
                    series: [{
                        data: this.fuwuY,
                        name:'服务得分',
                        type: 'bar',
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        },
                        barWidth: 26,
                        label: {
                            show: true,
                            position: 'insideBottom',
                            color: '#fff'
                        },
                        markLine: {
                            data: [
                                {yAxis: res.data[0].aavg, name: '平均值'},
                            ],
                        },
                        itemStyle: {
                            normal: {
                                barBorderRadius: [30, 30, 0, 0],
                                color: new echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        {offset: 0, color: '#51a1fa'},
                                        {offset: 0.5, color: '#5097f1'},
                                        {offset: 1, color: '#4b88e3'}
                                    ]
                                ),
                            },
                            emphasis: {
                                barBorderRadius: [30, 30, 0, 0],
                                color: new echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        {offset: 0, color: '#2378f7'},
                                        {offset: 0.7, color: '#2378f7'},
                                        {offset: 1, color: '#83bff6'}
                                    ]
                                )
                            },
                        },

                    }],
                    dataZoom: [
                        {
                            type: "slider",
                            show: true,
                            xAxisIndex: [0],
                            start: 0,
                            end:100,
                            textStyle:{
                                color:"#ccd7d7"
                            }
                        },{
                            type: 'inside',
                            start: 0,
                            end: 100
                        },
                    ]
                };
                myChart.setOption(option);
                window.addEventListener("resize", function () {
                    myChart.resize();
                });
                this.current=3;
            },
            async fenyuantongji(){
                const {data:res}= await this.$http.get('/v1.0/statistics/queryUnitCoefficient')
                if(res.status!=='3000'){
                    return this.$message.error('获取分院数据统计失败！'+res.message)
                }
                if(window.sessionStorage.getItem("roleId")==1){
                    this.fuwushow=false;
                    this.fuwu0=0;
                    this.fuwu1=0;
                    this.fuwu2=0;
                    this.fuwu0data=[]
                    this.fuwu1data=[]
                    this.fuwu2data=[]
                    for(let i in res.data){
                        if(res.data[i].coefficient==0.95){
                            this.fuwu0data.push(res.data[i].unitName)
                            this.fuwu0++
                        }else if(res.data[i].coefficient==1){
                            this.fuwu1data.push(res.data[i].unitName)
                            this.fuwu1++
                        }else {
                            this.fuwu2data.push(res.data[i].unitName)
                            this.fuwu2++
                        }
                    }
                }else {
                   this.fuwushow=true;
                   this.fuwufen=res.data.scoreCoefficient[0].serviceScore;
                   this.xishufen=res.data.scoreCoefficient[0].coefficient;
                }

            },
            async fenyuantongjilastyear(){
                const {data:res}= await this.$http.get('/v1.0/statisticsBeforeYear/queryUnitCoefficient?date='+this.selectyear)
                if(res.status!=='3000'){
                    return this.$message.error('获取分院数据统计失败！'+res.message)
                }
                if(window.sessionStorage.getItem("roleId")==1){
                    this.fuwushow=false;
                    this.fuwu0=0;
                    this.fuwu1=0;
                    this.fuwu2=0;
                    for(let i in res.data){
                        if(res.data[i].coefficient==0.95){
                            this.fuwu0data.push(res.data[i].unitName)
                            this.fuwu0++
                        }else if(res.data[i].coefficient==1){
                            this.fuwu1data.push(res.data[i].unitName)
                            this.fuwu1++
                        }else {
                            this.fuwu2data.push(res.data[i].unitName)
                            this.fuwu2++
                        }
                    }
                }else {
                    this.fuwushow=true;
                    this.fuwufen=res.data.scoreCoefficient[0].serviceScore;
                    this.xishufen=res.data.scoreCoefficient[0].coefficient;
                }
            },
            async toususj(){
                const {data:res}= await this.$http.get('/v1.0/complainEvent/findAllBeforeYear?date='+this.selectyear)
                this.tousudata=res.data;
            },
            async manyidusj(){
                const {data:res}= await this.$http.get('/v1.0/satisfaction/findAllBeforeYear?date='+this.selectyear)
                this.manyidudata.exceptEpc=res.data.exceptEpc;
                this.manyidudata.epc=res.data.epc;
            },
            tousuclick(){
                this.current1=1;
                this.istousu=true;
            },
            manyiduclcik(){
                this.current1=3;
                this.istousu=false;
            },
            async feiEpcclick(){
                this.current2=0;
                const {data:res}= await this.$http.get('/v1.0/statistics/queryExceptEpcUnitSatisfactionAverageScore')
                if(res.status!=='3000'){
                    return this.$message.error('获取总承包各单位满意度调查各项平均得分失败！'+res.message)
                }
                this.feiepcData=res.data;
                this.epcshow=true
            },
            async feiEpcclicklastyear(){
                this.current2=0;
                const {data:res}= await this.$http.get('/v1.0/statisticsBeforeYear/queryExceptEpcUnitSatisfactionAverageScore?date='+this.selectyear)
                if(res.status!=='3000'){
                    return this.$message.error('获取总承包各单位满意度调查各项平均得分失败！'+res.message)
                }
                this.feiepcData=res.data;
                this.epcshow=true
            },
            async epcclcik(){
                this.current2=1;
                const {data:res}= await this.$http.get('/v1.0/statisticsBeforeYear/queryEpcUnitSatisfactionAverageScore?date='+this.selectyear)
               if(res.status!=='3000'){
                   return this.$message.error('获取总承包各单位满意度调查各项平均得分失败！'+res.message)
               }
               this.epcData=res.data;
               this.epcshow=false
            },
            async exportexcle(){
              const {data:res}= await this.$http.get('/v1.0/export/exportSatisfactionStatistics/'+this.current2+'/'+this.selectyear)
                console.log(res)
                /*
                               const eleLink = document.createElement('a');
                               var blob = new Blob([res], {
                                   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                               });
                               eleLink.href =window.URL.createObjectURL(blob);
                               eleLink.download = '满意度统计';
                               // 触发点击
                               document.body.appendChild(eleLink);
                               eleLink.click();
                               // 然后移除
                               document.body.removeChild(eleLink);
                               if(res.status){
                                   return this.$message.error('导出表格失败！'+res.message)
                               }*/

                // window.location.href ='http://8.130.165.230:8815/'+res.data;
                 window.location.href ='https://customer.xjbtgis.cn/'+res.data;
            },
            dialogClose(){
                this.$refs.tousuFormRef.resetFields()
            },
            dialogClose1(){
                this.$refs.manyiduFormRef.resetFields()
            },
            dialogClose2(){
                this.$refs.manyiduFormRef2.resetFields()
            },
            tousuxiangqing(item){
               this.tousuForm=item
                this.dialogVisibleTsxq=true;
            },
            manyiduxiangqing(item){
                this.manyiduForm=item;
                this.dialogVisibleMydxq=true;
            },
            manyiduxiangqing2(item){
                this.manyiduForm2=item;
                this.dialogVisibleMydxq2=true;
            },
            async paiming(){
                const {data:res}= await this.$http.get('/v1.0/statisticsBeforeYear/queryServiceCoefficientSortStatistics?date='+this.selectyear)
                if(res.status!=='3000'){
                    return this.$message.error('查询单位排名失败！'+res.message)
                }
                this.paimingData=res.data
            },
            async innerOrder(){
                const {data:res}= await this.$http.get('/v1.0/statisticsBeforeYear/queryMarketSatisfactionCount?date='+this.selectyear)
                if(res.status!=='3000'){
                    return this.$message.error('查询院内满意度调查数量失败！'+res.message)
                }
                this.orderbaoyuan=res.data
                const {data:res2}= await this.$http.get('/v1.0/satisfaction/market/findAll')
                if(res2.status!=='3000'){
                    return this.$message.error('查询院内满意度调查数量占比失败！'+res2.message)
                }
                this.ordermanyiall=res2.data.length
                this.orderbaoyuanzhanbi=(this.orderbaoyuan/res2.data.length).toFixed(2)*100
            },
            async tousutongji(){
                this.current3=1;
                this.tousuTitle1='各单位投诉个数所占比例';
                this.tousuTitle2='各区域投诉个数所占比例';
                this.leftechart();
            },
            async manyidutongji(){
                this.current3=3;
                this.tousuTitle1='各单位满意度分数所占比例';
                this.tousuTitle2='各区域满意度分数所占比例';
                this.rightechart()
            },
            async leftechart(){
                const {data:res}= await this.$http.get('/v1.0/statisticsBeforeYear/queryComplainCountRatio?date='+this.selectyear)
                if(res.status!=='3000'){
                    return this.$message.error('查询投诉统计失败！'+res.message)
                }
                this.fenyuantongjiData=[];
                for(let i in res.data.unitCountRation){
                    this.fenyuantongjiData.push({name:res.data.unitCountRation[i].unitName,value:(res.data.unitCountRation[i].ratio*100).toFixed(0)})
                }
                this.difangData=[];
                for(let i in res.data.inAndOutCountRatio){
                    this.difangData.push({name:res.data.inAndOutCountRatio[i].cityName,value:(res.data.inAndOutCountRatio[i].ratio*100).toFixed(0)})
                }
                // this.difangData.push({name:'疆外',value:res.data.inAndOutCountRatio[0].outRatio},{name:'疆内',value:res.data.inAndOutCountRatio[0].inRatio})
                var chartDom = document.getElementById('lefttongji');
                var myChart = echarts.init(chartDom);
                var chartDom1 = document.getElementById('righttongji');
                var myChart1 = echarts.init(chartDom1);
                var option;
                option ={
                    title: {
                        text: this.tousuTitle1,
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter:function(params) {
                            console.log(params)
                            var str ='单位统计' + '<br>'
                                str += `${params.marker}`+params.name + ' : ' + params.value + '%<br>'
                            return str;
                        }
                    },
                    /*  legend: {
                          orient: 'vertical',
                          left: 'left',
                      },*/
                    grid: {
                        top: 0,
                        left: 0,
                        right: 0,
                        width:'auto',
                        height:'auto'
                    },
                    series: [
                        {
                            name: '单位统计',
                            type: 'pie',
                            radius: '50%',
                            data: this.fenyuantongjiData,
                            avoidLabelOverlap: true,

                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                };
                myChart.setOption(option);
                var option1;
                option1 ={
                    title: {
                        text: this.tousuTitle2,
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter:function(params) {
                            console.log(params)
                            var str ='区域统计' + '<br>'
                            str += `${params.marker}`+params.name + ' : ' + params.value + '%<br>'
                            return str;
                        }
                    },
                    /* legend: {
                         orient: 'vertical',
                         left: 'right',
                     },*/
                    grid: {
                        top: 0,
                        left: 0,
                        right: 0,
                        width:'auto',
                        height:'auto'
                    },
                    series: [
                        {
                            name: '区域统计',
                            type: 'pie',
                            radius: '50%',
                            data: this.difangData,
                            avoidLabelOverlap: true,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                };
                myChart1.setOption(option1);
                var a = document.getElementsByTagName("canvas")[1];
                var par = a.parentNode;
                par.style.height = 0;
                var a1 = document.getElementsByTagName("canvas")[0];

                var par1 = a1.parentNode;
                par1.style.height = 0;
                window.addEventListener("resize", function () {
                    myChart.resize();
                    myChart1.resize();
                });
            },
            async rightechart(){
                const {data:res}= await this.$http.get('/v1.0/statisticsBeforeYear/querySatisfactionScoreRatio?date='+this.selectyear)
                if(res.status!=='3000'){
                    return this.$message.error('查询投诉统计失败！'+res.message)
                }
                this.fenyuantongjiData=[];
                for(let i in res.data.unitSatisfactionRation){
                    this.fenyuantongjiData.push({name:res.data.unitSatisfactionRation[i].unitName,value:res.data.unitSatisfactionRation[i].ratio})
                }
                this.difangData=[];
                for(let i in res.data.inAndOutSatisfactionRatio){
                    this.difangData.push({name:res.data.inAndOutSatisfactionRatio[i].cityName,value:res.data.inAndOutSatisfactionRatio[i].ratio})
                }
                var chartDom = document.getElementById('lefttongji');
                var myChart = echarts.init(chartDom);
                var chartDom1 = document.getElementById('righttongji');
                var myChart1 = echarts.init(chartDom1);
                var option;
                option ={
                    title: {
                        text: this.tousuTitle1,
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    /*legend: {
                        orient: 'vertical',
                        left: 'left',
                    },*/
                    grid: {
                        top: 0,
                        left: 0,
                        right: 0,
                        width:'auto',
                        height:'auto'
                    },
                    series: [
                        {
                            name: '各单位统计',
                            type: 'pie',
                            radius: '50%',
                            data: this.fenyuantongjiData,
                            avoidLabelOverlap: true,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                };
                myChart.setOption(option);
                var option1;
                option1 ={
                    title: {
                        text: this.tousuTitle2,
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    /* legend: {
                         orient: 'vertical',
                         left: 'right',
                     },*/
                    grid: {
                        top: 0,
                        left: 0,
                        right: 0,
                        width:'auto',
                        height:'auto'
                    },
                    series: [
                        {
                            name: '各单位统计',
                            type: 'pie',
                            radius: '50%',
                            data: this.difangData,
                            avoidLabelOverlap: true,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                };
                myChart1.setOption(option1);
                var a = document.getElementsByTagName("canvas")[1];
                var par = a.parentNode;
                par.style.height = 0;
                window.addEventListener("resize", function () {
                    myChart.resize();
                    myChart1.resize();
                });
            },
        },
        created(){
            //this.fenyuantongji()
            this.fenyuantongjilastyear()
            this.tousushijianlastyear();
            this.toususj();
            this.manyidusj();
            //this.feiEpcclick();
            this.feiEpcclicklastyear();
            this.paiming();
            this.innerOrder();
        },
        mounted() {
            this.leftechart();
           // this.contont();
            this.$nextTick(() => {
                eventBus.$on('toyear',(message)=>{
                    this.selectyear=message+'-1-1'
                    console.log(this.selectyear)
                    //投诉事件统计
                    this.tousushijianlastyear();
                    // 服务评价统计
                    this.fenyuantongjilastyear();
                    //投诉事件列表
                    this.toususj();
                    //满意度事件列表
                    this.manyidusj();
                    this.tousuclick();
                    //非EPC满意度数据统计
                    this.feiEpcclicklastyear();
                    //分院分数统计表
                    this.current=2;
                    this.contont();
                })
            });
        },
        beforeDestroy(){
            eventBus.$off('toyear')
        }
    }
</script>

<style scoped lang="less">
     #all{
     width: 100%;
     height: 99.5%;
     background: #f5f7ff;
     border-top: 1px solid #E5E8FB;
     }
     #head{
         display: flex;
         justify-content: space-between;
         width: 70.5%;
         padding-left: 2%;
         height: 18%;
         margin-top: 1%;
         float: left;
     }
     #content{
         display: flex;
         justify-content: space-between;
         width: 96%;
         height: 46%;
         padding-left: 2%;
     }
     #cont{
         width: 73.5%;
         height: 100%;
     }
     #right{
         margin-top: -8.8%;
         width: 24%;
         height: 139%;
         box-shadow:5px 2px 10px rgba(172,196,254,0.36);
     }
     #bottom{
         width: 96%;
         height: 27%;
         top: 70%;
         margin-left: 2%;
         position: absolute;
         box-shadow:0 2px 15px rgba(172,196,254,0.36);
     }
    #topleft{
        width: 31%;
        height: 88%;
    }
    #conleft{
        width: 31%;
        height: 88%;
    }
     #conleft2{
         width: 31%;
         height: 88%;
     }
    #rightleft{
     width: 31%;
     height: 88%;
    }
     #rightleft2{
         width: 31%;
         height: 88%;

     }
    .divkuang{
        background: #fff;
        border-radius: 10px;
        box-shadow: -5px 2px 10px rgba(172,196,254,0.36);
        border: 1px solid #E9EFFF;
    }
     .seamless-warp{
         width: 100%;
         height: calc(100% - 16px);
         overflow: hidden;
     }
     /deep/ .cell{
         text-align: center;
     }
     /deep/ .el-table__empty-text{
         line-height:40px
     }
     /deep/ .el-table__empty-block{
         min-height: 40px;
     }
     /deep/ .el-table__body-wrapper {
         /*height: 200px; !* 滚动条整体高 必须项 *!*/
         border-right: none;
         overflow-y: scroll;/* overflow-y为了不出现水平滚动条*/
     }
     /deep/ .el-dialog--center .el-dialog__body{
         padding: 10px;
     }
     /deep/ .el-table__body-wrapper::-webkit-scrollbar {
         width:6px;/* 滚动条的宽高 必须项 */
         height: 5px;
     }

     /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
         background-color: #bfcbd9;/* 滚动条的宽 */
         border-radius: 3px;
     }
     /deep/ .el-input__inner{
         border-radius: 45px;
     }
     /deep/ .el-progress__text{
         white-space: pre-wrap;
     }
     .toolsbtn:hover{
         cursor:pointer;
         font-size: 16px !important;
     }
    .activie{
        border: 2px solid #4B8AE5 !important;
        background-color: #4B8AE5;
        color: #eaedfa !important;
    }
    /deep/ .el-dialog{
        margin-top: 10vh !important;
        border-radius: 8px;
    }

</style>
