<template>
    <div id="droot" >
        <div style="width: 20%;height:94.7%;background-color: #fff;margin-top: 1%;margin-left: 2%;border-radius: 15px; box-shadow: 0px 2px 15px rgba(172,196,254,0.36);">
            <div style="width: 100%;height: 5%">
                <div style="background-color: #4E90EA;width: 1.2%;height: 55%;margin-left: 9%;margin-top: 4%;float: left">
                </div>
                <div style="float: left;margin-left: 3.5%;height: 95%">
                    <p style="font-size: 1.35rem;color: #4b4e53;font-weight: bold;margin-top: 12.5%;">事件列表</p>
                </div>
                <div style="float: right;width: 40%;margin-top: 3.5%;height: 80%;margin-right: 2.5%">
                    <div class="toolsbtn"  style="width: 45%;height: 80%;text-align:center; border-radius: 15px  0 0 15px;border: 2px solid #e2e9f2;color: #a6abb3;float: left;font-size: 15px;line-height: 170%;" :class="{activie:current==1}" @click="tousuclick">投诉</div>
                    <div class="toolsbtn" style="width: 45%;height: 80%;text-align:center; border-radius: 0px  15px 15px 0px;border: 2px solid #e2e9f2;color: #a6abb3;float: left;font-size: 15px;line-height: 170%;" :class="{activie:current==3}" @click="manyiduclcik">满意度</div>
                </div>
            </div>
            <div style="width: 100%;height: 92%;overflow: hidden;overflow-y: scroll;">
                <div v-show="istousu" style="background-color: #f4f7fe;width: 90%;height: 19%;margin-left: 5%;border-radius: 0.6rem;box-shadow: 0px 6px 10px rgba(172,196,254,0.5);" v-for="item in tousudata" @click="tousuxiangqing(item)">
                    <div style="height: 10%;background-color: white"></div>
                    <div style="margin-left: 4%;"><span style="font-size: 1.2rem;color:#4b4e53;">{{item.projectName}}</span>
                        <div v-if="item.isRead==0" class="toolsbtn" style="width: 16%;height: 80%;text-align:center; border-radius: 15px;border: 2px solid red;color: #eaedfa;float: left;font-size: 14px;float: right;background-color: red" >未读</div>
                        <div v-else class="toolsbtn" style="width: 16%;height: 70%;text-align:center; border-radius: 15px;border: 2px solid #4B8AE5;color: #eaedfa;float: left;font-size: 14px;float: right;background-color: #4B8AE5" >已回复</div>
                    </div>
                    <div style="margin-left: 4%;"><span style="color:#a6abb3;font-size: 0.9rem;display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;">投诉内容：{{item.complainContent}}</span></div>
                    <div style="margin-left: 4%;"><span :title=item.answer style="color:#a6abb3;font-size: 0.9rem;white-space: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;">回复内容: {{item.answer}}</span></div>
                    <div style="margin-left: 4%;"><span style="color:#a6abb3;font-size: 0.9rem">{{item.complainUnit}}</span> <span style="float: right;color:#a6abb3;font-size: 0.9rem;margin-right: 3%">{{item.complainDate}}</span></div>
                </div>
                <div  v-show="!istousu" style="width: 100%;height: 40px">
                    <div style="text-align: center">
                        <el-button size="mini" type="success" @click="NOEPCmanyidudaochu">导出非Epc项目</el-button>
                        <el-button size="mini" type="success" @click="EPCmanyidudaochu">导出Epc项目</el-button>
                    </div>
                    <span class="line"></span>
                    　　<span class="text" style="color: green;font-size: 1.0rem">非Epc项目</span></span>
                    　　<span class="line"></span>
                </div>
                <div v-show="!istousu"  style="background-color: #f4f7fe;width: 90%;height: 20%;margin-left: 5%;border-radius: 0.6rem;box-shadow: 0px 6px 10px rgba(172,196,254,0.5);" v-for="item in manyidudata.exceptEpc"  @click="manyiduxiangqing2(item)">
                    <div style="height: 10%;background-color: white"></div>
                    <div style="margin-left: 4%;"><span style="font-size: 1.2rem;color:#4b4e53;">{{item.projectName}}</span>
                    </div>
                    <div style="margin-left: 4%;"><span style="color:#a6abb3;font-size: 0.9rem;display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;">满意度总得分：{{item.totalScore}}</span></div>
                    <div style="margin-left: 4%;"><span :title=item.opinion style="color:#a6abb3;font-size: 0.9rem;white-space: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;">合理化建议: {{item.opinion}}</span></div>
                    <div style="margin-left: 4%;"><span style="color:#a6abb3;font-size: 0.9rem">{{item.unitName}}</span> <span style="float: right;color:#a6abb3;font-size: 0.9rem;margin-right: 3%">{{item.scoreDate}}</span></div>
                </div>
                <div v-show="!istousu" style="width: 100%;height: 10px">
                    <span class="line"></span>
                    　　<span class="text" style="color: green;font-size: 1.0rem">Epc项目</span>
                    　　<span class="line" style="width: 40%"></span>
                </div>
                <div v-show="!istousu" style="background-color: #f4f7fe;width: 90%;height: 20%;margin-left: 5%;border-radius: 0.6rem;box-shadow: 0px 6px 10px rgba(172,196,254,0.5);" v-for="item in manyidudata.epc"  @click="manyiduxiangqing(item)">
                <div style="height: 10%;background-color: white"></div>
                <div style="margin-left: 4%;"><span style="font-size: 1.2rem;color:#4b4e53;">{{item.projectName}}</span>
                </div>
                <div style="margin-left: 4%;"><span style="color:#a6abb3;font-size: 0.9rem;display: -webkit-box;
                 -webkit-box-orient: vertical;
                overflow: hidden;
                 -webkit-line-clamp: 1;">满意度总得分：{{item.totalScore}}</span></div>
                <div style="margin-left: 4%;"><span :title=item.answer style="color:#a6abb3;font-size: 0.9rem;white-space: normal;
                display: -webkit-box;
                 -webkit-box-orient: vertical;
                overflow: hidden;
                 -webkit-line-clamp: 2;">合理化建议: {{item.opinion}}</span></div>
                <div style="margin-left: 4%;"><span style="color:#a6abb3;font-size: 0.9rem">{{item.unitName}}</span> <span style="float: right;color:#a6abb3;font-size: 0.9rem;margin-right: 3%">{{item.scoreDate}}</span></div>
                </div>

            </div>
        </div>

        <div style="width: 77%;height: 97%;display: flex;flex-wrap:wrap;flex-direction:row;justify-content:space-around;margin-top: 1%;border-radius: 18px;margin-right: 1%">

        <!--<div style="width: 31.5%;height: 15%;background-color: #fff;margin-left: 1%;border-radius: 10px; box-shadow: -5px 2px 10px rgba(172,196,254,0.36);">
            <span style="color: red;font-size: 40px;position: absolute;margin-left: 19%;margin-top: 0.2%;text-align: right">{{tousuall}}</span>
            <div style="width: 100%;height: 40%">
                <div style="background-color: #4E90EA;width: 1.5%;height: 40%;margin-left: 10%;margin-top: 4%;float: left">
                </div>
                <div style="float: left;margin-left: 4%;height: 95%">
                    <p style="font-size: 1.35rem;color: #4b4e53;font-weight: bold;margin-top: 10.5%;">投诉事件统计</p>
                </div>
            </div>
            <div style="width: 100%;height: 20%;display: flex;margin-top: 3%">
                <div style="width: 65%;padding-left: 15%">
                    <span style="color: #a6abb3;font-size: 1.1rem">已处理：{{yichuli}}</span>
                </div>
                <div style="color: #a6abb3;width: 25%">{{yichulizhanbi}}%</div>
            </div>
            <div style="width: 82%;height: 5%;background-color: #D6D6D6;margin-left: 10%;margin-top: 2%;border-radius: 1rem">
                <div style="width: 85%;height: 100%;background-color: red;border-radius: 1rem" :style="'width:' + yichulizhanbi + '%'"></div>
            </div>
        </div>
        <div style="width: 31.5%;height: 15%;background-color: #fff;border-radius: 10px; box-shadow: -5px 2px 10px rgba(172,196,254,0.36);">
            <span style="color: red;font-size: 40px;position: absolute;margin-left: 19%;margin-top: 0.2%;text-align: right">{{manyiall}}</span>
            <div style="width: 100%;height: 40%">
                <div style="background-color: #4E90EA;width: 1.5%;height: 40%;margin-left: 10%;margin-top: 4%;float: left">
                </div>
                <div style="float: left;margin-left: 4%;height: 95%">
                    <p style="font-size: 1.35rem;color: #4b4e53;font-weight: bold;margin-top: 7%;">顾客满意度调查统计</p>
                </div>
            </div>
            <div style="width: 100%;height: 20%;display: flex;margin-top: 3%">
                <div style="width: 65%;padding-left: 15%">
                    <span style="color: #a6abb3;font-size: 1.1rem">无抱怨：{{baoyuan}}</span>
                </div>
                <div style="color: #a6abb3;width: 25%">{{baoyuanzhanbi}}%</div>
            </div>
            <div style="width: 82%;height: 5%;background-color: #D6D6D6;margin-left: 10%;margin-top: 2%;border-radius: 1rem">
                <div style="height: 100%;background-color: red;border-radius: 1rem"  :style="'width:' + baoyuanzhanbi + '%'"></div>
            </div>
        </div>
        <div style="width: 31.5%;height: 15%;background-color: #fff;border-radius: 10px; box-shadow: 5px 2px 10px rgba(172,196,254,0.36);">
            <div style="width: 100%;height: 40%">
                <div style="background-color: #4E90EA;width: 1.5%;height: 40%;margin-left: 10%;margin-top: 4%;float: left">
                </div>
                <div style="float: left;margin-left: 4%;height: 95%">
                    <p style="font-size: 1.35rem;color: #4b4e53;font-weight: bold;margin-top: 10.5%;">服务评价统计</p>
                </div>
            </div>
            <div style="width: 100%;height: 20%;display: flex;margin-top: 3%">
                <div style="width: 25%;padding-left: 15%">
                    <span style="color: #a6abb3;font-size: 1.1rem">0.95：</span>
                    <el-popover
                            placement="top-start"
                            trigger="hover"
                    >
                        <div v-for="item in fuwu0data"  class="text item">
                            {{ item }}
                        </div>
                        <span style="font-size: 1.4rem;color: red"  slot="reference">{{fuwu0}}</span>
                    </el-popover>
                </div>
                <div style="width: 25%;padding-left: 3%">
                    <span style="color: #a6abb3;font-size: 1.1rem">1.00：</span>
                    <el-popover
                            placement="top-start"
                            trigger="hover"
                    >
                        <div v-for="item in fuwu1data"  class="text item">
                            {{ item }}
                        </div>
                        <span style="font-size: 1.4rem;color: blue"  slot="reference">{{fuwu1}}</span>
                    </el-popover>
                </div>
                <div style="width: 25%;padding-left: 3%">
                    <span style="color: #a6abb3;font-size: 1.1rem">1.05：</span>
                    <el-popover
                            placement="top-start"
                            trigger="hover"
                    >
                        <div v-for="item in fuwu2data" class="text item">
                            {{ item }}
                        </div>
                        <span style="font-size: 1.4rem;color: green"  slot="reference">{{fuwu2}}</span>
                    </el-popover>
                </div>
            </div>
        </div>
        <div style="width: 64.5%;height: 50%;background-color: #fff;margin-left: 1%;border-radius: 15px; box-shadow: -5px 2px 10px rgba(172,196,254,0.36);" id="map" v-show="mapshow">

        </div>
        <div style="width: 64.5%;height: 50%;background-color: #fff;margin-left: 1%;border-radius: 15px; box-shadow: -5px 2px 10px rgba(172,196,254,0.36);" id="tongji" v-show="!mapshow">
            <div style="width: 100%;height: 12%">
                <div style="background-color: #4E90EA;width: 0.7%;height: 45%;margin-left: 5%;margin-top: 1.5%;float: left">
                </div>
                <div style="float: left;margin-left: 1.7%;height: 95%">
                    <p style="font-size: 1.35rem;color: #4b4e53;font-weight: bold;margin-top: 22.5%;">统计</p>
                </div>
                <div style="float: left;width: 15%;margin-top: 1.1%;height: 60%;margin-left: 2.5%">
                    <div class="toolsbtn"  style="width: 45%;height: 80%;text-align:center; border-radius: 15px  0 0 15px;border: 2px solid #e2e9f2;color: #a6abb3;float: left;font-size: 15px;line-height: 170%;" :class="{activie:current3==1}" @click="tousutongji">投诉</div>
                    <div class="toolsbtn" style="width: 45%;height: 80%;text-align:center; border-radius: 0px  15px 15px 0px;border: 2px solid #e2e9f2;color: #a6abb3;float: left;font-size: 15px;line-height: 170%;" :class="{activie:current3==3}" @click="manyidutongji">满意度</div>
                </div>
            </div>
            <div style="width: 100%;height: 88%">
                <div style="width: 55%; height: 100%;float: left">
                    <div style="width: 100%;height: 100%;" id="lefttongji" >
                    </div>
                </div>
                <div style="width: 45%; height: 100%;float: left">
                    <div style="width: 100%;height: 100%;" id="righttongji">
                    </div>
                </div>
            </div>

        </div>
        <div style="position: absolute;z-index: 999;width: 20%;height: 5%;left: 53%;top: 23.6%;">
                <div style="float: right;width: 40%;margin-top: 3.5%;height: 60%;margin-right: 2.5%">
                    <div class="toolsbtn"  style="width: 45%;height: 80%;text-align:center; border-radius: 15px  0 0 15px;border: 2px solid #e2e9f2;color: #a6abb3;float: left;font-size: 15px;line-height: 170%;cursor: pointer" :class="{activie:current2==1}" @click="tongjiclick">统计</div>
                    <div class="toolsbtn" style="width: 45%;height: 80%;text-align:center; border-radius: 0px  15px 15px 0px;border: 2px solid #e2e9f2;color: #a6abb3;float: left;font-size: 15px;line-height: 170%;cursor: pointer" :class="{activie:current2==3}" @click="mapclcik">地图</div>
                </div>
        </div>
        <div style="width: 31.5%;height: 50%;background-color: #fff;border-radius: 15px;box-shadow: 5px 2px 10px rgba(172,196,254,0.36);">
            <div style="width: 100%;height: 8%">
                <div style="background-color: #4E90EA;width: 1.3%;height: 70%;margin-left: 9%;margin-top: 5%;float: left">
                </div>
                <div style="float: left;margin-left: 3.5%;height: 95%">
                    <p style="font-size: 1.35rem;color: #4b4e53;font-weight: bold;margin-top: 12.5%;">投诉/满意度情况</p>
                </div>
                <div style="float: right;width: 30%;margin-top: 4%;height: 80%;margin-right: 2.5%">
                    <div class="toolsbtn"  style="width: 45%;height: 85%;text-align:center; border-radius: 15px  0 0 15px;border: 2px solid #e2e9f2;color: #a6abb3;float: left;font-size: 15px;line-height: 170%;" :class="{activie:current1==1}" @click="tousuclick">投诉</div>
                    <div class="toolsbtn" style="width: 45%;height: 85%;text-align:center; border-radius: 0px  15px 15px 0px;border: 2px solid #e2e9f2;color: #a6abb3;float: left;font-size: 15px;line-height: 170%;" :class="{activie:current1==3}" @click="manyiduclcik">满意度</div>
                </div>
            </div>
            <div style="width: 100%;height: 88%;overflow: hidden;overflow-y: scroll">
                <div v-show="istousu" style="background-color: #f4f7fe;width: 90%;height: 30%;margin-left: 5%;border-radius: 0.6rem;margin-top: 3%;box-shadow: 0px 6px 10px rgba(172,196,254,0.5);" v-for="item in tousudata" @click="tousuxiangqing(item)">
                    <div style="margin-left: 4%;padding-top: 2%"><span style="font-size: 1.2rem;color:#4b4e53;margin-top: 2%">{{item.projectName}}</span>
                    </div>
                    <div style="margin-left: 4%;padding-top: 2%"><span style="color:#a6abb3;font-size: 0.9rem;display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;">投诉内容：{{item.complainContent}}</span></div>
                    <div style="margin-left: 4%;padding-top: 1%"><span :title=item.answer style="color:#a6abb3;font-size: 0.9rem;white-space: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;">回复内容： {{item.answer}}</span></div>
                    <div style="margin-left: 4%;padding-top: 1.5%"><span style="color:#a6abb3;font-size: 0.9rem;overflow: hidden;width: 70%">{{item.complainUnit}}</span> <span style="float: right;color:#a6abb3;font-size: 0.9rem;margin-right: 3%;">{{item.complainDate}}</span></div>
                </div>
                <div v-show="!istousu" style="background-color: #f4f7fe;width: 90%;height: 30%;margin-left: 5%;border-radius: 0.6rem;margin-top: 3%;box-shadow: 0px 6px 10px rgba(172,196,254,0.5);" v-for="item in manyidudata.exceptEpc"  @click="manyiduxiangqing2(item)">
                    <div style="margin-left: 4%;padding-top: 2%"><span style="font-size: 1.2rem;color:#4b4e53;margin-top: 2%">{{item.projectName}}</span>
                    </div>
                    <div style="margin-left: 4%;padding-top: 2%"><span style="color:#a6abb3;font-size: 0.9rem;display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;">满意度总得分：{{item.totalScore}}</span></div>
                    <div style="margin-left: 4%;padding-top: 1%"><span :title=item.opinion style="color:#a6abb3;font-size: 0.9rem;white-space: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;">合理化建议: {{item.opinion}}</span></div>
                    <div style="margin-left: 4%;padding-top: 1.5%"><span style="color:#a6abb3;font-size: 0.9rem">{{item.unitName}}</span> <span style="float: right;color:#a6abb3;font-size: 0.9rem;margin-right: 3%">{{item.scoreDate}}</span></div>
                </div>
                <div v-show="!istousu" style="background-color: #f4f7fe;width: 90%;height: 30%;margin-left: 5%;border-radius: 0.6rem;margin-top: 4%;box-shadow: 0px 6px 10px rgba(172,196,254,0.5);" v-for="item in manyidudata.epc"  @click="manyiduxiangqing(item)">
                    <div style="margin-left: 4%;padding-top: 2%"><span style="font-size: 1.2rem;color:#4b4e53;margin-top: 2%">{{item.projectName}}</span>
                    </div>
                    <div style="margin-left: 4%;padding-top: 2%"><span style="color:#a6abb3;font-size: 0.9rem;display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;">满意度总得分：{{item.totalScore}}</span></div>
                    <div style="margin-left: 4%;padding-top: 1%"><span :title=item.answer style="color:#a6abb3;font-size: 0.9rem;white-space: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;">合理化建议: {{item.opinion}}</span></div>
                    <div style="margin-left: 4%;padding-top: 1.5%"><span style="color:#a6abb3;font-size: 0.9rem">{{item.unitName}}</span> <span style="float: right;color:#a6abb3;font-size: 0.9rem;margin-right: 3%">{{item.scoreDate}}</span></div>
                </div>
            </div>
        </div>
            -->

        <div style="width: 98%;height: 48%;background-color: #fff;border-radius: 15px;margin-left: 1%;box-shadow: 0px 2px 10px rgba(172,196,254,0.36);">
            <div style="width: 100%;height: 10%;margin-top:0.6%;">
                <div style="background-color: #4E90EA;width: 0.4%;height:55%;margin-left: 3.3%;margin-top:0.4%;float: left">
                </div>
                <div style="float: left;margin-left: 1%;height: 85%">
                    <p style="font-size: 1.35rem;color: #4b4e53;font-weight: bold;margin-top: 2%;">处罚统计</p>
                </div>
                <div style="float: left;margin-left: 1%;height: 80%;width: 22%;margin-top:-0.2%;" id="bumen">
                    <el-select v-model="unitCode" clearable  filterable placeholder="请选择分院" style="width: 100%;height: 50%;margin-top: 1.5%" @change="changeunit">
                        <el-option
                                v-for="item in unitData"
                                :key="item.unitCode"
                                :label="item.unitName"
                                :value="item.unitCode">
                        </el-option>
                    </el-select>
                </div>
                <div style="float: right;margin-right: 2%"> <el-button size="mini" type="success" @click="dialogVisibleMydAddUnit=true">新增满意度</el-button></div>
            </div>
            <div style="width: 100%; height: calc(88% - 5px);overflow: hidden;">
                <div style="height: 100%;margin-left: 3%">
                    <el-table
                            :data="fenyuanData"
                            stripe
                            height="100%"
                            style="width: 100%"
                    >
                        <el-table-column
                                type="index"
                                label="序号"
                                min-width="2%"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="projectName"
                                label="项目名称"
                                min-width="15%">
                        </el-table-column>
                        <el-table-column
                                prop="complainedUnit"
                                label="分院名称"
                                min-width="10%">
                        </el-table-column>
                        <el-table-column
                                prop="complainDate"
                                label="被投诉时间"
                                min-width="6%">
                        </el-table-column>
                        <el-table-column
                                prop="manyidefen"
                                label="投诉处理报告"
                                min-width="15%">
                            <template slot-scope="scope">
                                <div v-if="scope.row.complainFile==null">
                                    <el-upload
                                            :headers="headers"
                                            ref="upload1"
                                            class="upload-demo"
                                            name="multipartFile"
                                            action="http://8.130.165.230:8815/api/v1.0/complainFile/uploadFile/"
                                            :data="{complainId:scope.row.id}"
                                            :on-preview="handlePreview"
                                            :on-success="handleAvatarSuccess"
                                            :on-change="getFile"
                                            :on-exceed="handleExceed"
                                            accept=".doc, .docx"
                                            >
                                        <el-button size="mini" type="success">上传</el-button>
                                    </el-upload>
                                </div>
                                <div v-else>
                                    <el-link :href="'http://8.130.165.230:8815'+scope.row.complainFile.filePath" :underline="false" target="view_window" style="font-size: 15px">{{scope.row.complainFile.fileName }}</el-link>
                                    <el-upload
                                            :headers="headers"
                                            ref="upload1"
                                            class="upload-demo"
                                            name="multipartFile"
                                            action="http://8.130.165.230:8815/api/v1.0/complainFile/uploadFile/"
                                            :data="{complainId:scope.row.id}"
                                            :on-preview="handlePreview"
                                            :on-success="handleAvatarSuccess"
                                            :on-change="getFile"
                                            :on-exceed="handleExceed"
                                            accept=".doc, .docx"
                                    >
                                        <el-button size="mini"  round>再次上传</el-button>
                                    </el-upload>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="complainDeductScore"
                                label="扣分分数"
                                min-width="6%">
                            <template slot-scope="scope">
                                <div v-if="scope.row.complainDeductScore!=null">

                                    <a @click="koufenshow(scope.row.id,scope.row.remark,scope.row.complainDeductScore)" style="cursor: pointer;text-decoration: underline;color: red">{{scope.row.complainDeductScore}}</a>
                                   <!-- <el-button round size="mini" >修改</el-button>-->
                                </div>
                                <div v-else>
                                    <el-button round size="mini" @click="koufenshow(scope.row.id)">填写</el-button>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="fuwudefen"
                                label="操作"
                                min-width="8%">
                            <template slot-scope="scope">
                                <div style="display: flex;
                               align-items: center;

                               justify-content: center;
                               vertical-align: middle;" @click="tousuxiangqing1(scope.row)">
                                    <img src="../assets/img/定位.png" style="width: 25px;" class="dingwei"><span class="dingwei" style="margin-left: 1px;color: #6996e4">定位</span>
                                </div>
                              <!--  <div style="display: flex;
                               align-items: center;
                               justify-content: center;
                               vertical-align: middle;float: left" @click="lahei(scope.row)">
                                    <img src="../assets/img/黑名单.png" style="width: 25px;" class="dingwei"><span class="dingwei" style="margin-left: 1px;color: #6996e4">拉入黑名单</span>
                                </div>-->
                            </template>
                        </el-table-column>

                    </el-table>
                </div>
            </div>
        </div>
        <div style="width: 98%;height: 47%;background-color: #fff;margin-left: 1%;border-radius: 15px; box-shadow: -5px 2px 10px rgba(172,196,254,0.36);" id="map" >

        </div>
        </div>

        <el-dialog
                title="投诉事件回复"
                :visible.sync="dialogVisible"
                width="25%"
                center
                :before-close="handleClose"
                @close="dialogClose"
        >
            <el-form ref="tousuFormRef" :model="tousuForm" label-width="80px" >
                <el-form-item label="被投诉名" prop="projectName">
                    <el-input v-model="tousuForm.projectName" disabled></el-input>
                </el-form-item>
                <el-form-item label="投诉人" prop="complainedPerson" >
                    <el-input v-model="tousuForm.complainedPerson" disabled></el-input>
                </el-form-item>
                <el-form-item label="投诉单位" prop="complainedUnit" >
                    <el-input v-model="tousuForm.complainedUnit" disabled></el-input>
                </el-form-item>
                <el-form-item label="投诉内容" prop="complainContent">
                    <el-input  type="textarea" :rows="4"  v-model="tousuForm.complainContent" disabled></el-input>
                </el-form-item>
                <el-form-item label="回复内容" prop="answer">
                    <el-input  type="textarea" :rows="4" placeholder="请输入回复内容" v-model="tousuForm.answer" v-bind:disabled="isdis"></el-input>
                </el-form-item>
                <el-form-item label="回复日期" prop="answerDate">
                    <el-date-picker
                            v-model="tousuForm.answerDate"
                            type="date"
                            placeholder="选择日期"
                            :disabled="isdis"
                    >
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
     <el-button type="goon" round size="medium" @click="dialogVisible = false">取 消</el-button>
    <el-button type="goon" round size="medium" @click="huifu">确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="扣分表填写"
                :visible.sync="dialogVisibleKf"
                width="45%"
                center
                :before-close="handleClose"
        >
            <el-table
                    ref="singleTable"
                    :data="koufenData"
                    highlight-current-row
                    @selection-change="handleSelectionChange"
                    style="width: 100%">
                <el-table-column
                        type="selection"
                        min-width="3%"  >
                </el-table-column>

                <el-table-column
                        property="type"
                        label="类别"
                        min-width="5%">
                </el-table-column>
                <el-table-column
                        property="yingxiang"
                        label="造成影响"
                        min-width="15%">
                </el-table-column>
                <el-table-column
                        property="koufen"
                        label="扣分情况"
                        min-width="5%">
                </el-table-column>
                <el-table-column
                        property="yiju"
                        label="计分依据"
                        min-width="50%">
                </el-table-column>
            </el-table>
            <el-input
                    type="textarea"
                    :rows="3"
                    placeholder="请输入投诉备注"
                    v-model="textarea">
            </el-input>
            <span slot="footer" class="dialog-footer">
     <el-button type="goon" round size="medium" @click="dialogVisibleKf = false">取 消</el-button>
    <el-button type="goon" round size="medium" @click="koufening">确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="投诉事件详情"
                :visible.sync="dialogVisibleTsxq"
                width="30%"
                center
                @close="dialogClosetsxq"
        >
            <el-form  ref="tousuFormRef" :model="tousuForm" label-width="15%" >
                <el-form-item label="项目名称" prop="projectName">
                    <el-input v-model="tousuForm.projectName" disabled></el-input>
                </el-form-item>
                <el-form-item label="被投诉人" prop="complainedPerson" >
                    <el-input v-model="tousuForm.complainedPerson" disabled></el-input>
                </el-form-item>
                <el-form-item label="被投诉单位" prop="complainedUnit" >
                    <el-input v-model="tousuForm.complainedUnit" disabled></el-input>
                </el-form-item>
                <el-form-item label="投诉人" prop="complainPerson" >
                    <el-input v-model="tousuForm.complainPerson" disabled></el-input>
                </el-form-item>
                <el-form-item label="投诉单位" prop="complainUnit" >
                    <el-input v-model="tousuForm.complainUnit" disabled></el-input>
                </el-form-item>
                <el-form-item label="投诉内容" prop="complainContent">
                    <el-input  type="textarea" :rows="4"  v-model="tousuForm.complainContent" disabled></el-input>
                </el-form-item>
                <el-form-item label="回复内容" prop="answer">
                    <el-input  type="textarea" :rows="4" placeholder="请输入回复内容" v-model="tousuForm.answer" v-bind:disabled="isdis"></el-input>
                </el-form-item>
                <el-form-item label="回复人" prop="answerPerson">
                    <el-input v-model="tousuForm.answerPerson" v-bind:disabled="isdis"></el-input>
                </el-form-item>
                <el-form-item label="回复日期" prop="answerDate">
                    <el-date-picker
                            v-model="tousuForm.answerDate"
                            type="date"
                            v-bind:disabled="isdis">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="goon" round size="medium" @click="feichu">研 判</el-button>
                <el-button type="goon" round size="medium" @click="dialogVisibleTsxq = false">关 闭</el-button>
                <el-button type="goon" round size="medium" @click="huifu">确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="满意度事件详情"
                :visible.sync="dialogVisibleMydxq"
                width="45%"
                center
                @close="dialogClose1"
        >
            <el-form :inline="true"  ref="manyiduFormRef" :model="manyiduForm" label-width="24%" >
                <el-form-item label="项目名称" prop="projectName" style="width: 48%">
                    <el-input v-model="manyiduForm.projectName" disabled></el-input>
                </el-form-item>
                <el-form-item label="满意度总分" prop="totalScore" style="width: 48%">
                    <el-input v-model="manyiduForm.totalScore" disabled></el-input>
                </el-form-item>
                <el-form-item label="项目总体评价" prop="overallScore" style="width: 48%">
                    <el-input v-model="manyiduForm.overallScore" disabled></el-input>
                </el-form-item>
                <el-form-item label="项目质量控制" prop="qualityScore" style="width: 48%">
                    <el-input v-model="manyiduForm.qualityScore" disabled></el-input>
                </el-form-item>
                <el-form-item label="项目进度控制" prop="processScore" style="width: 48%">
                    <el-input v-model="manyiduForm.processScore" disabled></el-input>
                </el-form-item>
                <el-form-item label="项目投资控制" prop="investmentScore"style="width: 48%" >
                    <el-input v-model="manyiduForm.investmentScore" disabled></el-input>
                </el-form-item>
                <el-form-item label="项目安全管理" prop="securityScore" style="width: 48%" >
                    <el-input v-model="manyiduForm.securityScore" disabled></el-input>
                </el-form-item>
                <el-form-item label="项目合同管理" prop="contractScore" style="width: 48%">
                    <el-input v-model="manyiduForm.contractScore" disabled></el-input>
                </el-form-item>
                <el-form-item label="能力工作态度" prop="attitudeScore" style="width: 48%">
                    <el-input v-model="manyiduForm.attitudeScore" disabled></el-input>
                </el-form-item>
                <el-form-item label="总体评价" prop="responsibleScore" style="width: 48%">
                    <el-input v-model="manyiduForm.responsibleScore" disabled></el-input>
                </el-form-item>
                <el-form-item label="再合作意愿" prop="cooperationScore" style="width: 48%">
                    <el-input v-model="manyiduForm.cooperationScore" disabled></el-input>
                </el-form-item>
                <el-form-item label="推荐意愿" prop="recommendScore" style="width: 48%">
                    <el-input v-model="manyiduForm.recommendScore" disabled></el-input>
                </el-form-item>
                <el-form-item label="填写人单位" prop="customerUnit" style="width: 48%">
                    <el-input v-model="manyiduForm.customerUnit" disabled></el-input>
                </el-form-item>
                <el-form-item label="联系方式" prop="customerTel" style="width: 48%">
                    <el-input v-model="manyiduForm.customerTel" disabled></el-input>
                </el-form-item>
                <el-form-item label="合理化建议" prop="opinion" style="width: 48%">
                    <el-input  type="textarea" :rows="4"  v-model="manyiduForm.opinion" disabled></el-input>
                </el-form-item>
                <el-form-item label="填写日期" prop="scoreDate" style="width: 48%">
                    <el-date-picker
                            v-model="manyiduForm.scoreDate"
                            type="date"
                            disabled>
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                  <el-button type="goon" round size="medium" @click="feichuMydEPC">研 判</el-button>
    <el-button type="goon" round size="medium" @click="dialogVisibleMydxq = false">关 闭</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="满意度事件详情"
                :visible.sync="dialogVisibleMydxq2"
                width="45%"
                center
                @close="dialogClose2"
        >
            <el-form :inline="true"  ref="manyiduFormRef2" :model="manyiduForm2" label-width="24%" >
                <el-form-item label="项目名称" prop="projectName" style="width: 48%">
                    <el-input v-model="manyiduForm2.projectName" disabled></el-input>
                </el-form-item>
                <el-form-item label="满意度总分" prop="totalScore" style="width: 48%">
                    <el-input v-model="manyiduForm2.totalScore" disabled></el-input>
                </el-form-item>
                <el-form-item label="项目总体评价" prop="overallScore" style="width: 48%">
                    <el-input v-model="manyiduForm2.overallScore" disabled></el-input>
                </el-form-item>
                <el-form-item label="项目计划执行" prop="processScore" style="width: 48%">
                    <el-input v-model="manyiduForm2.processScore" disabled></el-input>
                </el-form-item>
                <el-form-item label="及时性持续性" prop="timelyScore" style="width: 48%">
                    <el-input v-model="manyiduForm2.timelyScore" disabled></el-input>
                </el-form-item>
                <el-form-item label="响应配合度" prop="responseScore"style="width: 48%" >
                    <el-input v-model="manyiduForm2.responseScore" disabled></el-input>
                </el-form-item>
                <el-form-item label="工作态度" prop="attitudeScore" style="width: 48%" >
                    <el-input v-model="manyiduForm2.attitudeScore" disabled></el-input>
                </el-form-item>
                <el-form-item label="投诉处理情况" prop="complainHandleScore" style="width: 48%">
                    <el-input v-model="manyiduForm2.complainHandleScore" disabled></el-input>
                </el-form-item>
                <el-form-item label="合理适用性" prop="responseScore" style="width: 48%">
                    <el-input v-model="manyiduForm2.responseScore" disabled></el-input>
                </el-form-item>
                <el-form-item label="现场服务" prop="serviceScore" style="width: 48%">
                    <el-input v-model="manyiduForm2.serviceScore" disabled></el-input>
                </el-form-item>
                <el-form-item label="再合作意愿" prop="cooperationScore" style="width: 48%">
                    <el-input v-model="manyiduForm2.cooperationScore" disabled></el-input>
                </el-form-item>
                <el-form-item label="推荐意愿" prop="recommendScore" style="width: 48%">
                    <el-input v-model="manyiduForm2.recommendScore" disabled></el-input>
                </el-form-item>
                <el-form-item label="填写人单位" prop="customerUnit" style="width: 48%">
                    <el-input v-model="manyiduForm2.customerUnit" disabled></el-input>
                </el-form-item>
                <el-form-item label="联系方式" prop="customerTel" style="width: 48%">
                    <el-input v-model="manyiduForm2.customerTel" disabled></el-input>
                </el-form-item>
                <el-form-item label="合理化建议" prop="opinion" style="width: 48%">
                    <el-input  type="textarea" :rows="4"  v-model="manyiduForm2.opinion" disabled></el-input>
                </el-form-item>
                <el-form-item label="填写日期" prop="scoreDate" style="width: 48%">
                    <el-date-picker
                            v-model="manyiduForm2.scoreDate"
                            type="date"
                            disabled>
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                 <el-button type="goon" round size="medium" @click="feichuFEPC">研 判</el-button>
    <el-button type="goon" round size="medium" @click="dialogVisibleMydxq2 = false">关 闭</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="新增满意度事件(分院选择)"
                :visible.sync="dialogVisibleMydAddUnit"
                width="35%"
                center
        >
            <div style="width: 60%;text-align: center;margin-left: 20%">
                <el-select v-model="unitvalue" placeholder="请选择进行满意度调查的分院" style="width: 100%">
                    <el-option
                            v-for="item in unitData"
                            :key="item.unitId"
                            :label="item.unitName"
                            :value="`${item.unitCode},${item.unitName}`">
                    </el-option>
                </el-select>
            </div>
            <span slot="footer" class="dialog-footer">
                  <el-button type="goon" round size="medium" @click="dialogVisibleMydAddUnit = false">关 闭</el-button>
                 <el-button type="goon" round size="medium" @click="choseUnit">确定</el-button>

  </span>
        </el-dialog>
        <el-dialog
                title="新增满意度事件(非EPC)"
                :visible.sync="dialogVisibleMydAdd"
                width="55%"
                center
                @close="dialogCloseAdd"
        >
            <el-form :inline="true"  :rules="mydRules" ref="manyiduFormRefAdd" :model="manyiduFormAdd" label-width="43%" >
                <el-form-item label="项目名称" prop="projectName" style="width: 48%">
                    <el-input v-model="manyiduFormAdd.projectName" ></el-input>
                </el-form-item>
                <el-form-item label="项目所在片区" prop="provinceName"style="width: 48%" >
                    <el-cascader
                            :options="unitOptions"
                            ref="cascader"
                            :props="{ expandTrigger: 'hover' }"
                            @change="handleChangeAdd"
                           ></el-cascader>
                </el-form-item>
                <el-form-item label="顾客姓名" prop="customerName" style="width: 48%">
                    <el-input v-model="manyiduFormAdd.customerName" ></el-input>
                </el-form-item>
                <el-form-item label="顾客单位" prop="customerUnit" style="width: 48%">
                    <el-input v-model="manyiduFormAdd.customerUnit" ></el-input>
                </el-form-item>
                <el-form-item label="顾客单位地址" prop="address" style="width: 48%">
                    <el-input v-model="manyiduFormAdd.address" ></el-input>
                </el-form-item>
                <el-form-item label="顾客联系方式" prop="customerTel" style="width: 48%">
                <el-input v-model="manyiduFormAdd.customerTel" ></el-input>
               </el-form-item>
                <el-form-item label="项目总体评分" prop="overallScore" style="width: 48%">
                    <el-slider v-model="manyiduFormAdd.overallScore" :max="10"></el-slider>
               </el-form-item>
                <el-form-item label="项目进度计划执行情况评分" prop="processScore" style="width: 48%">
                    <el-slider v-model="manyiduFormAdd.processScore" :max="10"></el-slider>
                </el-form-item>
                <el-form-item label="项目服务的及时性和持续性评分" prop="timelyScore" style="width: 48%">
                    <el-slider v-model="manyiduFormAdd.timelyScore" :max="10"></el-slider>
                </el-form-item>
                <el-form-item label="响应顾客要求的配合度评分" prop="responseScore" style="width: 48%">
                    <el-slider v-model="manyiduFormAdd.responseScore" :max="10"></el-slider>
                </el-form-item>
                <el-form-item label="项目服务人员的工作态度评分" prop="attitudeScore" style="width: 48%">
                    <el-slider v-model="manyiduFormAdd.attitudeScore" :max="10"></el-slider>
                </el-form-item>
                <el-form-item label="投诉处理态度，投诉处理是否及时、有反馈，处理意见是否满意" prop="complainHandleScore" style="width: 48%">
                    <el-slider v-model="manyiduFormAdd.complainHandleScore" :max="10"></el-slider>
                </el-form-item>
                <el-form-item label="设计的合理性、适用性评分" prop="reasonableScore" style="width: 48%">
                    <el-slider v-model="manyiduFormAdd.reasonableScore" :max="10"></el-slider>
                </el-form-item>
                <el-form-item label="现场服务评分" prop="serviceScore" style="width: 48%">
                    <el-slider v-model="manyiduFormAdd.serviceScore" :max="10"></el-slider>
                </el-form-item>
                <el-form-item label="后续工程再度合作评分" prop="cooperationScore" style="width: 48%">
                    <el-slider v-model="manyiduFormAdd.cooperationScore" :max="10"></el-slider>
                </el-form-item>
                <el-form-item label="向其他单位推荐的意愿评分" prop="recommendScore" style="width: 48%">
                    <el-slider v-model="manyiduFormAdd.recommendScore" :max="10"></el-slider>
                </el-form-item>
                <el-form-item label="总分" style="width: 48%">
                    <span >{{sumMyd}}</span>
                </el-form-item>
                <el-form-item label="填写日期" prop="scoreDate"  style="width: 48%">
                    <el-date-picker
                            v-model="manyiduFormAdd.scoreDate"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            type="date"
                            >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="合理化建议" prop="opinion" style="width: 55%">
                    <el-input  type="textarea" :rows="2"  v-model="manyiduFormAdd.opinion" style="width: 280%" ></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">

    <el-button type="goon" round size="medium" @click="dialogVisibleMydAdd = false">关 闭</el-button>
                  <el-button type="goon" round size="medium" @click="saveSatis">确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="新增满意度事件(EPC)"
                :visible.sync="dialogVisibleMydAdd2"
                width="55%"
                center
                @close="dialogCloseAdd"
        >
            <el-form :inline="true"  :rules="mydRules" ref="manyiduFormRefAdd2" :model="manyiduFormAdd2" label-width="43%" >
                <el-form-item label="项目名称" prop="projectName" style="width: 48%">
                    <el-input v-model="manyiduFormAdd2.projectName" ></el-input>
                </el-form-item>
                <el-form-item label="项目所在片区" prop="provinceName"style="width: 48%" >
                    <el-cascader
                            :options="unitOptions"
                            ref="cascader2"
                            :props="{ expandTrigger: 'hover' }"
                            @change="handleChangeAdd2"
                    ></el-cascader>
                </el-form-item>
                <el-form-item label="顾客姓名" prop="customerName" style="width: 48%">
                    <el-input v-model="manyiduFormAdd2.customerName" ></el-input>
                </el-form-item>
                <el-form-item label="顾客单位" prop="customerUnit" style="width: 48%">
                    <el-input v-model="manyiduFormAdd2.customerUnit" ></el-input>
                </el-form-item>
                <el-form-item label="顾客单位地址" prop="address" style="width: 48%">
                    <el-input v-model="manyiduFormAdd2.address" ></el-input>
                </el-form-item>
                <el-form-item label="顾客联系方式" prop="customerTel" style="width: 48%">
                    <el-input v-model="manyiduFormAdd2.customerTel" ></el-input>
                </el-form-item>
                <el-form-item label="项目总体评分" prop="overallScore" style="width: 48%">
                    <el-slider v-model="manyiduFormAdd2.overallScore" :max="10"></el-slider>
                </el-form-item>
                <el-form-item label="项目质量控制评分" prop="qualityScore" style="width: 48%">
                    <el-slider v-model="manyiduFormAdd2.qualityScore" :max="10"></el-slider>
                </el-form-item>
                <el-form-item label="项目进度控制评分" prop="processScore" style="width: 48%">
                    <el-slider v-model="manyiduFormAdd2.processScore" :max="10"></el-slider>
                </el-form-item>
                <el-form-item label="项目投资控制评分" prop="investmentScore" style="width: 48%">
                    <el-slider v-model="manyiduFormAdd2.investmentScore" :max="10"></el-slider>
                </el-form-item>
                <el-form-item label="项目安全管理评分" prop="securityScore" style="width: 48%">
                    <el-slider v-model="manyiduFormAdd2.securityScore" :max="10"></el-slider>
                </el-form-item>
                <el-form-item label="项目合同管理评分" prop="contractScore" style="width: 48%">
                    <el-slider v-model="manyiduFormAdd2.contractScore" :max="10"></el-slider>
                </el-form-item>
                <el-form-item label="现场人员业务能力和工作态度评分" prop="attitudeScore" style="width: 48%">
                    <el-slider v-model="manyiduFormAdd2.attitudeScore" :max="10"></el-slider>
                </el-form-item>
                <el-form-item label="项目负责人总体评分" prop="responsibleScore" style="width: 48%">
                    <el-slider v-model="manyiduFormAdd2.responsibleScore" :max="10"></el-slider>
                </el-form-item>
                <el-form-item label="后续工程再度合作评分" prop="cooperationScore" style="width: 48%">
                    <el-slider v-model="manyiduFormAdd2.cooperationScore" :max="10"></el-slider>
                </el-form-item>
                <el-form-item label="向其他单位推荐的意愿评分" prop="recommendScore" style="width: 48%">
                    <el-slider v-model="manyiduFormAdd2.recommendScore" :max="10"></el-slider>
                </el-form-item>
                <el-form-item label="总分" style="width: 48%">
                    <span >{{sumMyd2}}</span>
                </el-form-item>
                <el-form-item label="填写日期" prop="scoreDate"  style="width: 48%">
                    <el-date-picker
                            v-model="manyiduFormAdd2.scoreDate"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            type="date"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="合理化建议" prop="opinion" style="width: 55%">
                    <el-input  type="textarea" :rows="2"  v-model="manyiduFormAdd2.opinion" style="width: 280%" ></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">

    <el-button type="goon" round size="medium" @click="dialogVisibleMydAdd2 = false">关 闭</el-button>
                  <el-button type="goon" round size="medium" @click="saveSatis2">确 定</el-button>
  </span>
        </el-dialog>
    </div>

</template>

<script>
    import { BMPGL } from "@/baigl.js";
    import * as echarts from 'echarts';
    import  eventBus from '../assets/js/eventBus'
    var map;

    export default {
        name: "tousu",
        data() {
            var validatorPhone = function (rule, value, callback) {
                if (value === '') {
                    callback(new Error('手机号不能为空'))
                } else if (!/^1\d{10}$/.test(value)) {
                    callback(new Error('手机号格式错误'))
                } else {
                    callback()
                }
            }
            return {
                yichuli:0,
                yichulizhanbi:0,
                baoyuan:0,
                baoyuanzhanbi:0,
                fenyuanData:[
                   /* {paiming:1,fenyuanname:'测绘分院',tousukoufen:0,manyidefen:100,baoyuancishu:0,fuwudefen:100,xiaoyixishu:1.0},
                    {paiming:1,fenyuanname:'测绘分院',tousukoufen:0,manyidefen:100,baoyuancishu:0,fuwudefen:100,xiaoyixishu:1.0},
                    {paiming:1,fenyuanname:'测绘分院',tousukoufen:0,manyidefen:100,baoyuancishu:0,fuwudefen:100,xiaoyixishu:1.0},
                    {paiming:1,fenyuanname:'测绘分院',tousukoufen:0,manyidefen:100,baoyuancishu:0,fuwudefen:100,xiaoyixishu:1.0},
                    {paiming:1,fenyuanname:'测绘分院',tousukoufen:0,manyidefen:100,baoyuancishu:0,fuwudefen:100,xiaoyixishu:1.0},
                    {paiming:1,fenyuanname:'测绘分院',tousukoufen:0,manyidefen:100,baoyuancishu:0,fuwudefen:100,xiaoyixishu:1.0},
                    {paiming:1,fenyuanname:'测绘分院',tousukoufen:0,manyidefen:100,baoyuancishu:0,fuwudefen:100,xiaoyixishu:1.0},
                    {paiming:1,fenyuanname:'测绘分院',tousukoufen:0,manyidefen:100,baoyuancishu:0,fuwudefen:100,xiaoyixishu:1.0},*/
                ],
                ak: "48MyaRLgK4oBFbI0Qxj6KXInLYuTQMKW",
                myMap: null,
                form:{
                    name: '',
                    region: '',
                    date1: '',
                    date2: '',
                    delivery: false,
                    type: [],
                    resource: '',
                    desc: ''
                },
                paimingData: [],
                current:1,
                istousu:true,
                tousudata:[],
                manyidudata:{
                    exceptEpc:null,
                    epc:null
                },
                dialogVisible:false,
                unitCode:'',
                unitData:[],
                uploadUrl:'',
                formData:null,
                dialogVisibleKf:false,
                koufenData:[
                    {type:'抱怨',yingxiang:'相对很小的影响',koufen:1,yiju:'人员不到位、因我方原因未按期提交成果、设代服务跟不上等'},
                    {type:'投诉',yingxiang:'相对较小的影响',koufen:2,yiju:'沟通不愉快、言行举止不得体等，未及时纠正和妥善解决或未解决的'},
                    {type:'投诉',yingxiang:'相对较重的影响',koufen:3,yiju:'产品设计和质量不过关，导致损失较重的；在处理客户投诉过程中，由于我方原因造成顾客投诉向负面方向发展的重复投诉等情况'},
                    {type:'投诉',yingxiang:'非常严重的影响',koufen:5,yiju:'顾客财产受到严重损失的；顾客投诉升级、处理难度极大的；顾客投诉上升到兵团层面并造成不良影响的；集团公司损失扩大、形象严重受损等情况'}
                    ],
                koufen:0,
                koufenprjid:null,
                current2:1,
                mapshow:false,
                fenyuantongjiData:[],
                difangData:[],
                fuwu0:0,
                fuwu1:0,
                fuwu2:0,
                fuwu0data:[],
                fuwu1data:[],
                fuwu2data:[],
                current3:1,
                textarea:'',
                isdis:false,
                tousuTitle1:'各单位投诉个数所在比例',
                tousuTitle2:'各区域投诉个数所在比例',
                dialogVisibleTsxq:false,
                tousuForm:{
                },
                dialogVisibleMydxq:false,
                dialogVisibleMydxq2:false,
                manyiduForm:{
                    projectName: '',
                    complainedPerson: '',
                    complainedUnit: '',
                    complainPerson: '',
                    complainUnit: false,
                    complainContent: [],
                    answer: '',
                },
                manyiduForm2:{

                },
                manyiduFormAdd:{
                "address": "",
                    'totalScore':100,
                "attitudeScore": 10,
                "cityCode": "",
                "cityName": "",
                "complainHandleScore": 10,
                "cooperationScore": 10,
                "countyCode": "",
                "countyName": "",
                "customerName": "",
                "customerTel": "",
                "customerUnit": "",
                "opinion": "",
                "overallScore": 10,
                "processScore": 10,
                "projectCode": "",
                "projectName": "",
                "provinceCode": "",
                "provinceName": "",
                "reasonableScore": 10,
                "recommendScore": 10,
                "responseScore": 10,
                "scoreDate": "",
                "serviceScore": 10,
                "timelyScore": 10,
                "unitCode": "",
                "unitName": ""
                },
                manyiduFormAdd2:{
                "address": "",
                "attitudeScore": 10,
                "cityCode": "",
                "cityName": "",
                "contractScore": 10,
                "cooperationScore": 10,
                "countyCode": "",
                "countyName": "",
                "customerName": "",
                "customerTel": "",
                "customerUnit": "",
                "investmentScore": 10,
                "opinion": "",
                "overallScore": 10,
                "processScore": 10,
                "projectCode": "",
                "projectName": "",
                "provinceCode": "",
                "provinceName": "",
                "qualityScore": 10,
                "recommendScore": 10,
                "responsibleScore": 10,
                "scoreDate": "",
                "securityScore": 10,
                "totalScore": 100,
                "unitCode": "",
                "unitName": ""
                },
                unitOptions:[],
                tousuall:0,
                manyiall:0,
                selectyear:'2023-1-1',
                dialogVisibleMydAdd:false,
                dialogVisibleMydAdd2:false,
                unitvalue:'',
                dialogVisibleMydAddUnit:false,
                mydRules:{
                    projectName: [
                        { required: true, message: '请输入项目名称', trigger: 'blur' }
                    ],
                    provinceName: [
                        { required: true, message: '请选择项目片区', trigger: 'blur' }
                    ],
                    customerName: [
                        { required: true, message: '请输入客户姓名', trigger: 'blur' }
                    ],
                    customerUnit: [
                        { required: true, message: '请输入客户单位', trigger: 'blur' }
                    ],
                    address: [
                        { required: true, message: '请输入客户地址', trigger: 'blur' }
                    ],
                    customerTel: [{required:true,validator: validatorPhone,trigger:'blur'}],
                    scoreDate:[
                        { required: true, message: '请选择打分时间', trigger: 'change' }
                    ],
                }
            }
        },
        methods: {
            initMap() {
                // 传入密钥获取地图回调。
                BMPGL(this.ak).then(async (BMapGL) => {
                    // 创建地图实例
                     map = new BMapGL.Map("map");
                    // 创建点坐标 axios => res 获取的初始化定位坐标
                    let point = new BMapGL.Point(87.62154983141609,43.80775527405459)
                    // 初始化地图，设置中心点坐标和地图级别
                    map.centerAndZoom(point, 19)
                    //开启鼠标滚轮缩放
                    map.enableScrollWheelZoom(true)
                    map.setHeading(64.5)
                    map.setTilt(73);
                    const {data:res}= await this.$http.get('/v1.0/complainEvent/findAll')
                  ; console.log( res.data)
                    for(let i = 0; i < res.data.length; i++){
                            var pt = new BMapGL.Point(res.data[i].latitude, res.data[i].longitude);
                            var icon = new BMapGL.Icon(require('../assets/img/客户头像.png'), new BMapGL.Size(20, 20));
                            var marker = new BMapGL.Marker3D(pt, Math.round(Math.random()) * 6000, {
                                size: 25,
                                icon: icon
                            });
                            map.addOverlay(marker);
                    }
                    // 保存数据
                    // this.myMap = map
                })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            async tousutj(){
                const {data:res}= await this.$http.get('/v1.0/statisticsBeforeYear/queryComplainEventAndSatisfactionHandling?date='+this.selectyear)
                this.yichuli=res.data.complain.handledCount;
                this.tousuall=res.data.complain.totalCount;
                this.yichulizhanbi=(res.data.complain.handledCount/res.data.complain.totalCount).toFixed(2)*100;
                this.baoyuan=res.data.satisfaction.noComplainCount;
                this.manyiall=res.data.satisfaction.totalCount;
                this.baoyuanzhanbi=(res.data.satisfaction.noComplainCount/res.data.satisfaction.totalCount).toFixed(2)*100
            },
            async toususj(){
                const {data:res}= await this.$http.get('/v1.0/complainEvent/findAllBeforeYear?date='+this.selectyear)
                this.tousudata=res.data;
            },
            tousuhuifu(item){
                this.tousuForm=item;
                this.tousuForm.answerDate=new Date();
                this.isdis=false;
                this.dialogVisible=true;
            },
            tousuhuifu1(item){
                this.tousuForm=item;
                this.isdis=true;
                this.dialogVisible=true;
            },
            async tousuxiangqing(item){
                console.log(item)
                BMPGL(this.ak).then((BMapGL) => {
                    // 创建地图实例
                    // 创建点坐标 axios => res 获取的初始化定位坐标
                    let point = new BMapGL.Point(item.latitude,item.longitude)
                    // 初始化地图，设置中心点坐标和地图级别
                    map.centerAndZoom(point, 12)
                    map.enableScrollWheelZoom(true);
                    map.setHeading(64.5);
                    map.setTilt(73);
                })
                    .catch((err) => {
                        console.log(err)
                    })
                this.tousuForm=JSON.parse(JSON.stringify(item));
                if(item.isRead==0){
                    this.tousuForm.answerDate=new Date();
                    this.isdis=false;
                }else {
                    this.isdis=true;
                }
                this.dialogVisibleTsxq=true;
                // const {data:res}= await this.$http.get('/v1.0/project/findByCode/'+item.projectCode)
                // this.form=res.data;
            },
            async tousuxiangqing1(item){
                BMPGL(this.ak).then((BMapGL) => {
                    // 创建地图实例
                    // 创建点坐标 axios => res 获取的初始化定位坐标
                    let point = new BMapGL.Point(item.latitude,item.longitude)
                    // 初始化地图，设置中心点坐标和地图级别
                    map.centerAndZoom(point, 13)
                })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            async lahei(row){
                console.log(row.complainTel)
                const {data:res}= await this.$http.put('/v1.0/owner/update/updateOwnerActivityByphone/'+row.complainTel)
                if(res.status!=="6000"){
                    return this.$message.error('添加黑名单失败！'+res.message)
                }
                this.$message.success('添加黑名单成功！');
                this.chufa()
            },
            async huifu(){
                if(this.isdis){
                   return this.dialogVisibleTsxq=false;
                }
                const {data:res}= await this.$http.put('/v1.0/complainEvent/updateComplain/'+this.tousuForm.answerDate+'/'+ this.tousuForm.answer+'/'+this.tousuForm.answerPerson+'/'+this.tousuForm.id)
               if(res.status!='6000'){
                   return this.$message.error('回复失败！'+res.message)
               }
                const {data:res1}= await this.$http.put('/v1.0/complainEvent/updateIsRead/1/'+this.tousuForm.id)
                if(res1.status!='6000'){
                    return this.$message.error('更新事件状态失败！'+res1.message)
                }
                this.$message.success(res.message);
                this.dialogVisibleTsxq=false;
                this.toususj()
            },
            dialogClose(){
                this.$refs.tousuFormRef.resetFields()
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            async manyidusj(){
                const {data:res}= await this.$http.get('/v1.0/satisfaction/findAllBeforeYear?date='+this.selectyear)
                this.manyidudata.exceptEpc=res.data.exceptEpc;
                this.manyidudata.epc=res.data.epc;
            },
            async getUnitData(){
                const {data:res}= await this.$http.get('/v1.0/unit/findAll')
                if(res.status!=='3000'){
                    return this.$message.error('获取单位列表失败！'+res.message)
                }
                this.unitData=res.data;
            },
            async chufa(){
                const {data:res}= await this.$http.get('/v1.0/complainEvent/findAllByProjectNameAndBeforeYear?unitCode='+this.unitCode+'&complainDate='+this.selectyear)
                this.fenyuanData=res.data;
            },
            handlePreview(file) {
                console.log(file);
            },
            getFile(file){
                this.formData=new FormData();
                this.formData.append("multipartFile",file.raw);
            },
            async handleAvatarSuccess(data){
                this.$message.success(data.message);
                this.chufa();
                //const {data:result}=await this.$http.post('/v1.0/complainFile/uploadFile/'+id,this.formData);
            },
            handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 10 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            handleSelectionChange(selection) {
                if(selection[0]==null){
                   return this.$refs.singleTable.clearSelection();
                }
                if(selection.length!=0){
                    this.koufen = selection[0].koufen;
                }else {
                    this.koufen =0
                }
                if (selection.length > 1) {
                    this.$refs.singleTable.clearSelection();
                    this.$refs.singleTable.toggleRowSelection(selection.pop());
                }
            },
            koufenshow(id,remark,score){
                this.koufenprjid=id;
                this.textarea=remark;
                this.dialogVisibleKf=true;
                this.$nextTick(() => {
                    this.$refs.singleTable.clearSelection();
                    this.$refs.singleTable.toggleRowSelection(this.koufenData[score-1]);
                })

            },
           async koufening(){
                if(this.textarea==''){
                    return this.$message.error('请输入投诉备注')
                }else {
                    const {data:res}= await this.$http.put('/v1.0/complainEvent/updateComplainDeductScore',{
                        complainDeductScore:this.koufen,
                        id:this.koufenprjid,
                        remark:this.textarea})
                    if(res.status!=='6000'){
                        return this.$message.error('扣分保存失败！'+res.message)
                    }
                    this.$message.success(res.message);
                    this.dialogVisibleKf=false;
                    this.chufa();
                    this.paiming();
                }

            },
            tousuclick(){
                this.current=1;
                this.istousu=true;
            },
            manyiduclcik(){
                this.current=3;
                this.istousu=false;
            },
            async sousutongji(){
                const {data:res}= await this.$http.get('/v1.0/statistics/queryComplainCountRatio')
                if(res.status!=='3000'){
                    return this.$message.error('查询投诉统计失败！'+res.message)
                }
                for(let i in res.data.inAndOutCountRatio){
                    this.fenyuantongjiData.push({name:res.data.inAndOutCountRatio[i].unitName,value:res.data.inAndOutCountRatio[i].ratio})
                }
                //

            },
            async tousutongji(){
              this.current3=1;
                this.tousuTitle1='各单位投诉个数所占比例';
                    this.tousuTitle2='各区域投诉个数所占比例';
                this.leftechart();
            },
            async manyidutongji(){
               this.current3=3;
                this.tousuTitle1='各单位满意度分数所占比例';
                this.tousuTitle2='各区域满意度分数所占比例';
                this.rightechart()
            },
            async leftechart(){
                const {data:res}= await this.$http.get('/v1.0/statisticsBeforeYear/queryComplainCountRatio?date='+this.selectyear)
                if(res.status!=='3000'){
                    return this.$message.error('查询投诉统计失败！'+res.message)
                }
                this.fenyuantongjiData=[];
                for(let i in res.data.unitCountRation){
                    this.fenyuantongjiData.push({name:res.data.unitCountRation[i].unitName,value:res.data.unitCountRation[i].ratio})
                }
                this.difangData=[];
                for(let i in res.data.inAndOutCountRatio){
                    this.difangData.push({name:res.data.inAndOutCountRatio[i].cityName,value:res.data.inAndOutCountRatio[i].ratio})
                }
                // this.difangData.push({name:'疆外',value:res.data.inAndOutCountRatio[0].outRatio},{name:'疆内',value:res.data.inAndOutCountRatio[0].inRatio})
               /* var chartDom = document.getElementById('lefttongji');
                var myChart = echarts.init(chartDom);
                var chartDom1 = document.getElementById('righttongji');
                var myChart1 = echarts.init(chartDom1);
                var option;
                option ={
                    title: {
                        text: this.tousuTitle1,
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                  /!*  legend: {
                        orient: 'vertical',
                        left: 'left',
                    },*!/
                    grid: {
                        top: 0,
                        left: 0,
                        right: 0,
                        width:'auto',
                        height:'auto'
                    },
                    series: [
                        {
                            name: '单位统计',
                            type: 'pie',
                            radius: '50%',
                            data: this.fenyuantongjiData,
                            avoidLabelOverlap: true,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                };
                myChart.setOption(option);
                var option1;
                option1 ={
                    title: {
                        text: this.tousuTitle2,
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                   /!* legend: {
                        orient: 'vertical',
                        left: 'right',
                    },*!/
                    grid: {
                        top: 0,
                        left: 0,
                        right: 0,
                        width:'auto',
                        height:'auto'
                    },
                    series: [
                        {
                            name: '区域统计',
                            type: 'pie',
                            radius: '50%',
                            data: this.difangData,
                            avoidLabelOverlap: true,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                };
                myChart1.setOption(option1);
                var a = document.getElementsByTagName("canvas")[1];
                var par = a.parentNode;
                par.style.height = 0;
                var a1 = document.getElementsByTagName("canvas")[0];

                var par1 = a1.parentNode;
                par1.style.height = 0;
                window.addEventListener("resize", function () {
                    myChart.resize();
                    myChart1.resize();
                });*/
            },
            async rightechart(){
               const {data:res}= await this.$http.get('/v1.0/statisticsBeforeYear/querySatisfactionScoreRatio?date='+this.selectyear)
                if(res.status!=='3000'){
                    return this.$message.error('查询投诉统计失败！'+res.message)
                }
                this.fenyuantongjiData=[];
                for(let i in res.data.unitSatisfactionRation){
                    this.fenyuantongjiData.push({name:res.data.unitSatisfactionRation[i].unitName,value:res.data.unitSatisfactionRation[i].ratio})
                }
                this.difangData=[];
                for(let i in res.data.inAndOutSatisfactionRatio){
                    this.difangData.push({name:res.data.inAndOutSatisfactionRatio[i].cityName,value:res.data.inAndOutSatisfactionRatio[i].ratio})
                }
                var chartDom = document.getElementById('lefttongji');
                var myChart = echarts.init(chartDom);
                var chartDom1 = document.getElementById('righttongji');
                var myChart1 = echarts.init(chartDom1);
                var option;
                option ={
                    title: {
                        text: this.tousuTitle1,
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    /*legend: {
                        orient: 'vertical',
                        left: 'left',
                    },*/
                    grid: {
                        top: 0,
                        left: 0,
                        right: 0,
                        width:'auto',
                        height:'auto'
                    },
                    series: [
                        {
                            name: '各单位统计',
                            type: 'pie',
                            radius: '50%',
                            data: this.fenyuantongjiData,
                            avoidLabelOverlap: true,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                };
                myChart.setOption(option);
                var option1;
                option1 ={
                    title: {
                        text: this.tousuTitle2,
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                   /* legend: {
                        orient: 'vertical',
                        left: 'right',
                    },*/
                    grid: {
                        top: 0,
                        left: 0,
                        right: 0,
                        width:'auto',
                        height:'auto'
                    },
                    series: [
                        {
                            name: '各单位统计',
                            type: 'pie',
                            radius: '50%',
                            data: this.difangData,
                            avoidLabelOverlap: true,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                };
                myChart1.setOption(option1);
                var a = document.getElementsByTagName("canvas")[1];
                var par = a.parentNode;
                par.style.height = 0;
                window.addEventListener("resize", function () {
                    myChart.resize();
                    myChart1.resize();
                });
            },
            async fuwupingjia(){
                const {data:res}= await this.$http.get('/v1.0/statisticsBeforeYear/queryUnitCoefficient?date='+this.selectyear)
                if(res.status!=='3000'){
                    return this.$message.error('获取分院数据统计失败！'+res.message)
                }
                this.fuwu0data=[]
                this.fuwu1data=[]
                this.fuwu2data=[]
                this.fuwu0=0;
                this.fuwu1=0;
                this.fuwu2=0;
                for(let i in res.data){
                    if(res.data[i].coefficient==0.95){
                        this.fuwu0data.push(res.data[i].unitName)
                        this.fuwu0++
                    }else if(res.data[i].coefficient==1){
                        this.fuwu1data.push(res.data[i].unitName)
                        this.fuwu1++
                    }else {
                        this.fuwu2data.push(res.data[i].unitName)
                        this.fuwu2++
                    }
                }
            },
            async paiming(){
                const {data:res}= await this.$http.get('/v1.0/statisticsBeforeYear/queryServiceCoefficientSortStatistics?date='+this.selectyear)
                if(res.status!=='3000'){
                    return this.$message.error('查询单位排名失败！'+res.message)
                }
                this.paimingData=res.data
            },
            mapclcik(){
                this.current2=3;
                this.mapshow=true;
            },
            tongjiclick(){
                this.current2=1;
                this.mapshow=false;
            },
            dingwei(x,y){

            },
            dialogClosetsxq(){
                this.$refs.tousuFormRef.resetFields()
            },
            manyiduxiangqing(item){
                this.manyiduForm=item;
                this.dialogVisibleMydxq=true;
            },
            manyiduxiangqing2(item){
                this.manyiduForm2=item;
                this.dialogVisibleMydxq2=true;
            },
            dialogClose1(){
                this.$refs.manyiduFormRef.resetFields()
            },
            dialogClose2(){
                this.$refs.manyiduFormRef2.resetFields()
            },
            dialogCloseAdd(){
                this.$refs.manyiduFormRefAdd.resetFields()
            },
            changeunit(data){
                console.log(data)
                this.unitCode=data;
                this.chufa();
            },
            async feichu(){
                const confirmResult = await this.$confirm('该条记录是否存在不合理的情况，如若存在-点击确定即可清除本条记录, 是否确定?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                const confirmResult2 = await this.$confirm('是否同时将该投诉业主拉入黑名单?', '提示', {
                    confirmButtonText: '拉入',
                    cancelButtonText: '不拉入',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult2 !== 'confirm'){
                    const {data:res}= await this.$http.delete('/v1.0/complainEvent/deleteComplainToAbandon?id='+this.tousuForm.id)
                    if(res.status!="4000"){
                        return  this.$message.error('删除失败！')
                    }
                    this.$message.success('删除成功！');
                }else {
                    const {data:res}= await this.$http.delete('/v1.0/complainEvent/deleteComplainToAbandon?id='+this.tousuForm.id+'&activity=1')
                    if(res.status!="4000"){
                        return  this.$message.error('拉入黑名单失败！')
                    }
                    this.$message.success('删除并拉入黑名单成功！');
                }
                //请求

                this.toususj();
                this.dialogVisibleTsxq = false
            },
            async feichuFEPC(){
                const confirmResult = await this.$confirm('该条记录是否存在不合理的情况, 如若存在-点击确定即可清除本条记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                const confirmResult2 = await this.$confirm('是否将该填写满意度的业主拉入黑名单?', '提示', {
                    confirmButtonText: '拉入',
                    cancelButtonText: '不拉入',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult2 !== 'confirm'){
                    const {data:res}= await this.$http.delete('/v1.0/satisfaction/deleteExceptEpcSatisfactionToAbandon?id='+this.manyiduForm2.id)
                    if(res.status!="4000"){
                        return  this.$message.error('删除失败！')
                    }
                    this.$message.success('删除成功！');
                }else {
                    const {data:res}= await this.$http.delete('/v1.0/satisfaction/deleteExceptEpcSatisfactionToAbandon?id='+this.manyiduForm2.id+'&activity=1')
                    if(res.status!="4000"){
                        return  this.$message.error('删除失败！')
                    }
                    this.$message.success('删除并拉入黑名单成功！');
                }
                this.manyidusj();
                this.manyiduclcik();
                this.dialogVisibleMydxq2 = false
            },
            async feichuMydEPC(){
                const confirmResult = await this.$confirm('该条记录是否存在不合理的情况，如若存在-点击确定即可清除本条记录, 是否确定?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                const confirmResult2 = await this.$confirm('是否将该填写满意度的业主拉入黑名单?', '提示', {
                    confirmButtonText: '拉入',
                    cancelButtonText: '不拉入',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult2 !== 'confirm'){
                    const {data:res}= await this.$http.delete('/v1.0/satisfaction/deleteEpcSatisfactionToAbandon?id='+this.manyiduForm.id)
                    if(res.status!="4000"){
                        return  this.$message.error('删除失败！')
                    }
                    this.$message.success('删除成功！');
                }else {
                    const {data:res}= await this.$http.delete('/v1.0/satisfaction/deleteEpcSatisfactionToAbandon?id='+this.manyiduForm.id+'&activity=1')
                    if(res.status!="4000"){
                        return  this.$message.error('拉入黑名单失败！')
                    }
                    this.$message.success('删除并拉入黑名单成功！');
                }
                this.manyidusj();
                this.manyiduclcik();
                this.dialogVisibleMydxq = false
            },
          async  getUnitArea(){
                const {data:res}= await this.$http.get('/v1.0/region/province/findAll')
                const {data:res2}= await this.$http.get('/v1.0/region/city/findAllByProvinceCode/650000')
                for(let i in res.data){
                    if(res.data[i].provinceCode!='650000'){
                        this.unitOptions.push({
                            value: res.data[i].provinceCode,
                            label:res.data[i].shortName
                        })
                    }
                }
                let xjdata=[]
                for(let j in res2.data){
                    if(res2.data[j].shortName){
                        xjdata.push({
                            value: res2.data[j].cityCode,
                            label:res2.data[j].shortName
                        })
                    }else {
                        xjdata.push({
                            value: res2.data[j].cityCode,
                            label:res2.data[j].cityName
                        })
                    }

                }
                this.unitOptions.unshift({value:'650000',label:'新疆',children:xjdata})
                console.log(this.unitOptions)
            },
            choseUnit(){
                console.log(this.unitvalue)
                this.dialogVisibleMydAddUnit=false
                if(this.unitvalue.split(",")[0]=='OD120719172903'||this.unitvalue.split(",")[0]=='OD170929172001'){
                    this.manyiduFormAdd2.unitCode=this.unitvalue.split(",")[0]
                    this.manyiduFormAdd2.unitName=this.unitvalue.split(",")[1]
                    this.dialogVisibleMydAdd2=true
                }else {
                    this.manyiduFormAdd.unitCode=this.unitvalue.split(",")[0]
                    this.manyiduFormAdd.unitName=this.unitvalue.split(",")[1]
                    this.dialogVisibleMydAdd=true
                }
            },
            handleChangeAdd(value){
                console.log(this.$refs["cascader"].getCheckedNodes()[0].label)
                if(value.length==1){
                    this.manyiduFormAdd.provinceName=this.$refs["cascader"].getCheckedNodes()[0].label
                    this.manyiduFormAdd.provinceCode=value[0]
                }else if(value.length==2) {
                    this.manyiduFormAdd.provinceName=this.$refs["cascader"].getCheckedNodes()[0].label
                    this.manyiduFormAdd.provinceCode=value[0]
                    this.manyiduFormAdd.cityName=this.$refs["cascader"].getCheckedNodes()[0].pathLabels[1]
                    this.manyiduFormAdd.cityCode=value[1]
                }
            },
            handleChangeAdd2(value){
                if(value.length==1){
                    this.manyiduFormAdd2.provinceName=this.$refs["cascader2"].getCheckedNodes()[0].label
                    this.manyiduFormAdd2.provinceCode=value[0]
                }else if(value.length==2) {
                    this.manyiduFormAdd2.provinceName=this.$refs["cascader2"].getCheckedNodes()[0].label
                    this.manyiduFormAdd2.provinceCode=value[0]
                    this.manyiduFormAdd2.cityName=this.$refs["cascader2"].getCheckedNodes()[0].pathLabels[1]
                    this.manyiduFormAdd2.cityCode=value[1]
                }
            },
            async  saveSatis(){
                this.$refs['manyiduFormRefAdd'].validate(async (valid) => {
                    if (valid) {
                        this.manyiduFormAdd.totalScore=this.sumMyd
                        const {data:res}= await this.$http.post('/v1.0/satisfaction/epcExcept/save',this.manyiduFormAdd)
                        if(res.status!=='6000'){
                            return this.$message.error('保存失败！'+res.message)
                        }
                        this.$message.success('保存成功！')
                        this.dialogVisibleMydAdd = false;
                        this.manyidusj();
                    } else {
                        console.log('填写不符合要求');
                        return false;
                    }
                });

            },
            async  saveSatis2(){
                this.$refs['manyiduFormRefAdd2'].validate(async(valid) => {
                    if (valid) {
                        this.manyiduFormAdd2.totalScore=this.sumMyd2
                        const {data:res}= await this.$http.post('/v1.0/satisfaction/save',this.manyiduFormAdd2)
                        if(res.status!=='6000'){
                            return this.$message.error('保存失败！'+res.message)
                        }
                        this.$message.success('保存成功！')
                        this.dialogVisibleMydAdd2= false;
                        this.manyidusj();
                    } else {
                        console.log('填写不符合要求');
                        return false;
                    }
                })
            },
            async NOEPCmanyidudaochu(){
                const {data:res}= await this.$http.get('/v1.0/export/exportStatisticsExcel?isEpc=0&date='+this.selectyear,{responseType:'blob'})
                const eleLink = document.createElement('a');
                var blob = new Blob([res], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                });
                eleLink.href =window.URL.createObjectURL(blob);
                eleLink.download = '非EPC满意度统计';
                // 触发点击
                document.body.appendChild(eleLink);
                eleLink.click();
                // 然后移除
                document.body.removeChild(eleLink);
                if(res.status){
                    return this.$message.error('导出表格失败！'+res.message)
                }
            },
            async EPCmanyidudaochu(){
                const {data:res}= await this.$http.get('/v1.0/export/exportStatisticsExcel?isEpc=1&date='+this.selectyear,{responseType:'blob'})
                const eleLink = document.createElement('a');
                var blob = new Blob([res], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                });
                eleLink.href =window.URL.createObjectURL(blob);
                eleLink.download = 'EPC满意度统计';
                // 触发点击
                document.body.appendChild(eleLink);
                eleLink.click();
                // 然后移除
                document.body.removeChild(eleLink);
                if(res.status){
                    return this.$message.error('导出表格失败！'+res.message)
                }
            }
        },
        created(){
          //  this.tousutj();
            this.fuwupingjia()
            this.toususj();
            this.getUnitArea();
            this.manyidusj();
            this.chufa();
            this.getUnitData();
           // this.paiming();

        },
        computed:{
            headers(){
                return{
                    'token':window.sessionStorage.getItem('token')
                }
            },
            sumMyd(){
                return this.manyiduFormAdd.serviceScore+this.manyiduFormAdd.reasonableScore+this.manyiduFormAdd.complainHandleScore+this.manyiduFormAdd.attitudeScore+this.manyiduFormAdd.responseScore
                    +this.manyiduFormAdd.timelyScore+this.manyiduFormAdd.processScore+this.manyiduFormAdd.overallScore+this.manyiduFormAdd.recommendScore+this.manyiduFormAdd.cooperationScore
            },
            sumMyd2(){
                return this.manyiduFormAdd2.overallScore+this.manyiduFormAdd2.qualityScore+this.manyiduFormAdd2.processScore+this.manyiduFormAdd2.investmentScore+this.manyiduFormAdd2.securityScore
                    +this.manyiduFormAdd2.contractScore+this.manyiduFormAdd2.attitudeScore+this.manyiduFormAdd2.responsibleScore+this.manyiduFormAdd2.cooperationScore+this.manyiduFormAdd2.recommendScore
            }
        },
        mounted() {
          this.initMap()
            //this.leftechart()
            this.$nextTick(() => {
                eventBus.$on('toyear',(message)=>{
                    this.selectyear=message+'-1-1'
                    console.log(this.selectyear)
                    //投诉事件统计
                    this.tousutj();
                    // 服务评价统计
                    this.fuwupingjia();
                    //投诉事件列表
                    this.toususj();
                    //满意度事件列表
                    this.manyidusj();
                    this.tousuclick();
                    this.tousutongji();
                    //处罚
                    this.chufa()
                    this.paiming();

                })
            });
        },

    }
</script>

<style scoped lang="less">
 #droot{

     width: 100%;
     height: 92.5vh;
     background: #f5f7ff;
     display: flex;
     flex-wrap:wrap;
     flex-direction:row;
     justify-content:space-around;
     border-top: 1px solid #E5E8FB;
 }
 .anchorBL{
     display:none;
 }
 /deep/ .cell{
     text-align: center;
 }
 /deep/ .el-table__empty-text{
     line-height:40px
 }
 /deep/ .el-table__empty-block{
     min-height: 40px;
 }
 /deep/ .el-table__body-wrapper {
     /*height: 200px; !* 滚动条整体高 必须项 *!*/
     border-right: none;
     overflow-y: scroll;/* overflow-y为了不出现水平滚动条*/
 }
 /deep/ .el-dialog--center .el-dialog__body{
     padding: 10px;
 }
 /deep/ .el-table__body-wrapper::-webkit-scrollbar {
     width:6px;/* 滚动条的宽高 必须项 */
     height: 5px;
 }

 /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
     background-color: #bfcbd9;/* 滚动条的宽 */
     border-radius: 3px;
 }
    #projSelect{
        /deep/ .el-input__inner{
            border-radius: 25px;
            height: 32px;
        }
        /deep/ .el-form-item{
            margin-bottom: 2px;
        }
        /deep/ .el-form-item__label{
            color: black;
            font-size: 16px;
        }
    }
    .dingwei:hover{
        cursor:pointer;
    }
 .activie{
     border: 2px solid #4B8AE5 !important;
     background-color: #4B8AE5;
     color: #eaedfa !important;
 }
 .toolsbtn:hover{
     cursor:pointer;
     font-size: 16px !important;
 }
 ::-webkit-scrollbar {
     width:6px;
 }
 ::-webkit-scrollbar-thumb {
     border-radius:10px;
     background:rgba(0,0,0,0.1);
     -webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
 }
 /deep/ .el-dialog{
     border-radius: 8px;
 }
 /deep/ .el-dialog__header {
     //background: -webkit-linear-gradient(left, #3ee8c2, #25e4a3);
     border-radius: 8px 8px 0 0 ;
 }
 /deep/ .el-dialog--center .el-dialog__body{
     padding: 10px;
 }
    #bumen{
        /deep/ .el-input__inner{
            border-radius: 20px;
            height: 32px;
        }
        /deep/ .el-input__icon{
            line-height: 35px !important;
        }
    }
    /deep/ .el-upload-list{
        display: none;
    }
    .line{
        display: inline-block;
        width:38%;
        border-top:1px solid #cccccc;
        vertical-align:5px;
      }
 /deep/ .el-dialog{
     margin-top: 8vh !important;
     border-radius: 8px;
 }
 /deep/ .el-slider__runway{
     width: 150px
 }
 /deep/ .el-message-box{
     width: 480px;
     p{
         font-size: 18px;
         line-height: 30px;
     }
 }

</style>
